import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    // padding: '10px 0',
    // boxSizing: 'border-box',
    // width: 360,
    height: '100%',
    // boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    // backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // borderRadius: 10,
    // position: 'absolute',
    // right: '0',
    zIndex: 10,
    // top: 0,
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  inputContainer: {
    // position: 'absolute',
    width: '100%',
    bottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    // '& > div:first-child': {
    //   width: '60% !important',
    //   backgroundColor: '#0074f8 !important',
    // },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '15px',
    // marginBottom: 10,
    height: '65%',
  },
  bigContent: {
    height: '100% !important',
    marginBottom: '20px',
  },
  loading: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
