import { makeStyles } from '@material-ui/core/styles';
import { he } from 'date-fns/locale';
import { RFC_2822 } from 'moment';

export default makeStyles(({ breakpoints: BP }) => ({
  chatContainer: {
    display: 'flex',
    // height: 'calc(100vh - 135px)',
    margin: '0 20px',
    marginTop: '10px',
  },
  chatListContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '25%',
    minWidth: '18%',
    background: '#FFFFFF',
    border: '1px solid #E1E4EA',
    boxShadow: '0px 0px 12px 0px rgba(34, 35, 40, 0.15)',
    borderRadius: 8,
    padding: '16px',
    // overflow: 'auto',
    position: 'sticky',
    top: 80,
    height: '86vh',
    '@media (max-width:1000px)': {
      flex: '1',
    },
  },
  orderDetailsLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  orderDetailTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 900,
    color: '#3d3d3d',
    marginBottom: '15px',
  },
  row2: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  avatar: {
    height: '60px !important',
    width: '60px !important',
    marginRight: '2px !important',
  },
  light2: {
    fontFamily: 'Averta Light',
    fontWeight: 300,
    color: '#3d3d3d',
    width: '60%',
    // overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  icon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    margin: '0 5px',
  },
  companyDetail: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0px 20px',
  },
  orderDetail: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 12px',
    justifyContent: 'space-between',
  },
  textContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  messagesContainer: {
    height: '100%',
    '@media (max-width:1000px)': {
      flex: '1',
    },
  },
  chatMessagesContainer: {
    background: '#FFFFFF',
    border: '1px solid #E1E4EA',
    borderRadius: 8,
    flex: '1',
    marginRight: '16px',
    position: 'relative',
    height: 'max-content',
    padding: 20,
    '@media (max-width:1000px)': {
      marginLeft: '0',
    },
  },
  tableTopActions: {
    display: 'flex',
    padding: '0px 0px 10px',
    gap: 10,
    borderBottom: '1px solid #E1E4EA',
    marginBottom: 15,
    fontSize: '18px',
    fontWeight: 900,
  },

  tableTopActionsChatIndex: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 0px 10px 6px',
    gap: 10,
    borderBottom: '1px solid #E1E4EA',
    marginBottom: 15,
    fontSize: '18px',
    fontWeight: 900,
    '& span': {
      display: 'flex',
      gap: 10,
    },
  },
  backBtn: {
    cursor: 'pointer',
    padding: '6px 15px',
    borderRadius: '50px',
    transition: 'all ease 0.3s',
    marginBottom: '-4px',
    marginTop: '-8px',
    '&:hover': {
      background: '#ededed',
    },
  },
  tableTopActionsChat: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 0px 5px',
    gap: 10,
    borderBottom: '1px solid #E1E4EA',
    // marginBottom: 20,
  },
  tableTopActionsSpan: {
    display: 'flex',
    gap: 10,
  },
  searchBox: {
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: '10px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  searchIcon: {
    fill: 'rgba(0, 0, 0, 0.5)',
  },
  searchInput: {
    border: 'none',
    flex: '1',
    marginLeft: '10px',
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    '&:focus-visible': {
      border: 'none !important',
      outline: 'none',
    },
  },
  infoChat: {
    display: 'flex',
    flexDirection: 'column',
  },
  topSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: '1px solid #FFD268',
    padding: '16px 24px',
    borderRadius: '8px',
  },
  infoChatDiv: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  infoCircleIcon: {
    borderRadius: '8px',
    padding: '8px 8px 6px',
    background: '#F6B51E',
    height: 'max-Content',
  },
  desclineBtn: {
    height: 'max-content',
    border: '1px solid #000',
    color: '#000000',
    padding: '4px 12px',
    borderRadius: 8,
  },
  chatBox: {
    minHeight: '75vh',
    overflow: 'auto',
    // padding: '35px 15px',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  rowSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'center',
    width: '99%',
    padding: '0px 5px',
  },
  tickIconNew: {
    padding: '7px 10px',
    background: '#1FC16B',
    borderRadius: '50px',
    // marginLeft: '-45px',
  },
  sectionSpacer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 7,
  },
  sectionSpacerTitle: {
    width: 'fit-content',
    padding: '8px 16px',
    borderRadius: '50px',
    border: '1px solid #D0FBE9',
    background: '#E8F8F0',
    color: '#1FC16B',
  },

  sectionSpacerHr: {
    width: '-webkit-fill-available',
    borderTop: '1px solid #000000',
  },
  sectionDetail: {
    borderLeft: '2px dashed #3EE089',
    // marginLeft: 30,
    display: 'flex',
    padding: '15px 0px 15px 24px',
    gap: 20,
    alignItems: 'flex-end',
    width: '96%',
  },
  sectionDetailInprogress: {
    borderLeft: '2px dashed #99A0AE',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0px 15px 24px',
    gap: 20,
    alignItems: 'flex-end',
    width: '96%',
  },
  sectionDetailPassed: {
    borderLeft: '2px dashed #3EE089',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0px 15px 24px',
    gap: 20,
    alignItems: 'flex-end',
    width: '96%',
  },

  sectionDetailEmpty: {
    borderLeft: '2px dashed #99A0AE',
    // marginLeft: 30,
    display: 'flex',
    padding: '15px 0px 15px 24px',
    gap: 20,
    alignItems: 'flex-end',
    width: '96%',
  },
  usersInfoSecton: {
    border: '2px dashed #FFE097',
    background: '#FFFAEB',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
    width: '-webkit-fill-available',
    borderRadius: 16,
    padding: 24,
  },
  usersInfoSectonTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  usersInfoSectonSubTitle: {
    maxWidth: '65%',
    textAlign: 'center',
  },
  usersInfoSectonBody: {
    maxWidth: '100%',
    textAlign: 'center',
    display: 'flex',
    padding: '25px 10px',
  },
  usersInfoSectonBodySpan: {
    display: 'flex',
    alignItems: 'center',
    gap: 7,
  },
  usersInfoSectonBodyIcons: {
    display: 'flex',
    // background: '#F5F7FA',
    borderRadius: 8,
    // padding: 6,
  },
  usersInfoSectonBodyTitles: {
    fontWeight: 'bold',
  },
  usersInfoSectonSSlBtn: {
    minWidth: '150px',
    color: '#ffffff',
    padding: '5px 40px',
    border: '1px solid #F6B51E',
    borderRadius: 8,
    margin: '2px',
    marginTop: '20px',
    lineHeight: '1.3rem',
    background:
      'linear-gradient(0deg, #F6B51E 0%, rgba(255, 255, 255, 0.00) 1868.75%)',
    boxShadow:
      '0px 0px 0px 1px #F6B51E, 0px 1px 1px 0px rgba(14, 18, 27, 0.24)',
    '& span>div>div': {
      display: 'flex',
      gap: 10,
      '& div>div': {
        display: 'flex',
      },
    },
    '&:disabled': {
      // border: '1px solid #F5F7FA',
      boxShadow: 'none',
      background: '#F5F7FA',
      color: '#CACFD8',
    },
  },

  emptyTableHandler: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    width: '-webkit-fill-available',
    border: '1px solid #E1E4EA',
    borderRadius: 8,
  },
  emptyChatHandler: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '-webkit-fill-available',
    height: '-webkit-fill-available',
    height: '93%',
  },
  emptyChatIcon: {
    '& svg': {
      width: '100%',
      height: 'auto',
      marginBottom: 15,
    },
  },
  sectionDetailPreAprove: {
    display: 'flex',
    gap: 20,
    width: '95%',
    flexDirection: 'column',
  },
  pendingProgress: {
    border: '1.5px solid #335CFF',
    background: '#EBF1FF',
    borderRadius: '50px',
    color: '#335CFF',
    padding: '5px 12px',
  },
  pendingSectionSpacerTitle: {
    width: 'fit-content',
    padding: '8px 16px',
    borderRadius: '50px',
    border: '1px solid #f5f6f7',
    background: '#E7E8EC66',
    color: '#717784',
  },
  list: {
    // margin: '20px 0 0px',
    width: '100%',
    minWidth: '900px',
    border: '1px solid #E1E4EA',
    borderRadius: 8,
    padding: '4px 8px',
  },
  listPopup: {
    margin: '20px 0 0px',
    width: '100%',
    minWidth: '900px',
    borderRadius: 8,
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },

  listPreApprove: {
    // margin: '20px 0 0px',
    width: '100%',
    minWidth: '900px',
    borderRadius: 8,
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  tableRow: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 32,
    borderRadius: 5,
    // boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    // margin: '10px 0',
    fontFamily: 'Averta',
    fontSize: 11,
    fontWeight: 400,
    color: '#131517',
    textAlign: 'center',
    '& td': {
      padding: '0 5px',
      borderRight: '1px solid #E8E8E8',
      '&:last-child': {
        borderRight: 'none',
      },
    },
    '&:last-child': {
      marginBottom: 0,
    },
    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      // padding: 5,
      // display: 'flex',
    },
  },
  tableRowPreApprove: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    borderRadius: 8,
    border: '1px solid #E1E4EA',
    // boxShadow: '0px 0px 12px 0px #22232826',
    backgroundColor: '#fff',
    // margin: '10px 0',
    fontFamily: 'Averta',
    fontSize: 11,
    // fontWeight: 900,
    color: '#43424b',
    textAlign: 'center',
    '& td': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      borderRight: '1px solid #E8E8E8',
      padding: '0px 6px',
    },
    [BP.up('lg')]: {
      fontSize: 11,
    },
    '& div>div': {
      whiteSpace: 'nowrap',
      width: 'initial',
      // padding: 2,
      // display: 'flex',
    },
    // '&:hover': {
    //   backgroundColor: '#f5f5f5',
    // },
  },
  preApprovePickupLocation: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    display: 'inline-block !important',
  },
  tableBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  tableBodyPopup: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    maxHeight: 400,
    overflowY: 'auto',
  },
  timeSelectSpan: {
    display: 'flex',
    borderRadius: 5,
    border: '1px solid #E1E4EA',
    background: '#FFF',
    padding: 2,
  },
  galleryAddIcon: {
    display: 'flex',
    background: '#F5F7FA',
    borderRadius: 8,
    padding: 6,
  },
  galleryViewIcon: {
    display: 'flex',
    border: '1px solid #335cff',
    borderRadius: 8,
    padding: 6,
    '&:hover': {
      background: '#f3f3f3',
      cursor: 'pointer',
    },
    '& > div:first-child': {
      display: 'flex',
    },
  },
  galleryAddBlueIcon: {
    display: 'flex',
    border: '1px solid #335CFF',
    borderRadius: 8,
    padding: 6,
    '&:hover': {
      background: '#f3f3f3',
      cursor: 'pointer',
    },
    '& > div:first-child': {
      display: 'flex',
    },
  },
  galleryAddedIcon: {
    display: 'flex',
    border: '1px solid #1FC16B',
    borderRadius: 8,
    padding: 6,
    '&:hover': {
      background: '#f3f3f3',
      cursor: 'pointer',
    },
    '& > div:first-child': {
      display: 'flex',
    },
  },
  chatList: {
    minHeight: '96%',
    // padding: '0px 40px',
    '& > div:first-child': {
      '& > div:first-child': {
        display: 'none',
      },
    },
  },
  containerNumberInput: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  inputContainer: {
    // position: 'absolute',
    width: '100%',
    bottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    // '& > div:first-child': {
    //   width: '60% !important',
    //   backgroundColor: '#0074f8 !important',
    // },
  },
  containerConditions: {
    background: 'rgba(153, 160, 174, 0.16)',
    borderRadius: 50,
    minWidth: '50%',
    padding: '6px 20px',
  },
  containerInput: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  containerBadge: {
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    width: '120px',
    minWidth: '70px',
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 35,
    backgroundColor: '#fff',
    borderRadius: 8,
    // border: 'solid 1px #707070',
    '& div': {
      borderRadius: 8,
      '& fieldset': {
        borderRadius: 8,
        border: 'transparent',
      },
    },
    '& input': {
      fontFamily: 'Averta Light',
      padding: '9px 10px',
      fontSize: 11,
      borderRadius: 8,
      border: '1px solid #E1E4EA',
      '&:disabled': {
        background: '#F5F7FA',
        color: '#CACFD8',
        border: 'transparent',
      },
    },
  },

  containerSelectBtn: {
    display: 'flex',
    gap: 8,
    minWidth: '60px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 12px',
    borderRadius: 8,
    cursor: 'pointer',
    border: '1px solid #E1E4EA',
    background: '#FFF',
    '&:hover': {
      background: '#f3f3f3',
      cursor: 'pointer',
    },
  },
  containerPending: {
    display: 'flex',
    gap: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 12px',
    borderRadius: 50,
    color: '#717784',
    background: 'rgba(153, 160, 174, 0.16)',
  },
  tableRowSelectable: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    borderRadius: 8,
    border: '1px solid #E1E4EA',
    backgroundColor: '#fff',
    fontFamily: 'Averta',
    fontSize: 11,
    fontWeight: 500,
    color: '#43424b',
    textAlign: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
    '& td': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      borderRight: '1px solid #E8E8E8',
      padding: '0px 6px',
    },
    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
      display: 'flex',
    },
    '&:hover': {
      background: '#f3f3f3',
      cursor: 'pointer',
    },
  },
  checkbox: {
    marginRight: '10px',
    padding: 5,
    '& svg': {
      fill: '#000',
      width: '20px',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },
  tableRowSelected: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    borderRadius: 8,
    border: '1px solid #335CFF',
    boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#EBF1FF',
    fontFamily: 'Averta',
    fontSize: 11,
    fontWeight: 500,
    color: '#43424b',
    textAlign: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
    '& td': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      borderRight: '1px solid #E8E8E8',
      padding: '0px 6px',
    },
    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
      display: 'flex',
    },
  },

  thead: {
    display: 'flex',
    fontFamily: 'Averta light',
    fontSize: 11,
    // fontWeight: 600,
    // lineHeight: 1.2,
    color: '#999999',
    textAlign: 'center',
    borderBottom: '1px solid #E8E8E8',
    alignItems: 'center',
    height: '25px',
    padding: '4px 4px 8px',
    '& th': {
      padding: '0 5px',
      borderRight: '1px solid #E8E8E8',
      '&:last-child': {
        borderRight: 'none',
      },
    },
    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  theadPreApprove: {
    display: 'flex',
    fontFamily: 'Averta light',
    fontSize: 11,
    // fontWeight: 600,
    // lineHeight: 1.2,
    color: '#999999',
    textAlign: 'center',
    border: '1px solid #E1E4EA',
    background: '#F5F7FA',
    borderRadius: '8px 8px 0px 0px',
    boxShadow:
      '0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)',
    alignItems: 'center',
    height: '25px',
    padding: '4px 4px 8px',
    '& th': {
      padding: '0 5px',
      borderRight: '1px solid #E8E8E8',
      '&:last-child': {
        borderRight: 'none',
      },
    },
    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  tbody: {
    // display: 'flex',
    // flexDirection: 'column',
    // width:'fit-content'
  },

  loading: {
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBox: {
    width: '400px',
    minHeight: '397px',
    background: '#FFFFFF',
    borderRadius: '5px',
    padding: '10px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  closeModal: {
    textAlign: 'end',
    '& img': {
      width: '10px',
      height: '10px',
      cursor: 'pointer',
    },
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '23px',
    color: '#000000',
    margin: '0 0 20px 0',
  },
  containersTableContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    flex: '1',
  },
  popup: {
    // position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'rgba(19, 21, 23, 0.40)',
    backdropFilter: 'blur(6px)',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
  },

  card: {
    width: '1550px',
    position: 'relative',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 16,
    border: '1px solid #E8E8E8',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '75%',
    overflow: 'auto',
  },
  loading: {
    height: '100%',
    margin: 0,
  },
  uploadImageCard: {
    width: '650px',
    position: 'relative',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 16,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '84.5%',
    // overflow: 'auto',
  },
  loading: {
    height: '100%',
    margin: 0,
  },
  imageUploadSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // gap: '10px',
    margin: '15px 0 0',
    border: '1.5px dashed #99A0AE',
    borderRadius: 20,
    height: '180px',
    width: '100%',
  },
  imageUploadArea: {
    marginTop: '-10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  imageUploadIcon: {
    marginTop: '-25px',
    '& svg': {
      width: '140px',
      height: '90px',
    },
  },
  dragDropText: {
    fontSize: '20px',
    color: '#131517',
    fontWeight: 900,
  },
  dragDropOrText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    flexDirection: 'row',
    fontSize: 12,
    color: '#999',
    fontWeight: 300,
    gap: 10,
  },
  orHr: {
    width: '80px',
    borderTop: '1px solid #E1E4EA',
  },
  fileTypeInfo: {
    fontSize: 12,
    color: '#99A0AE',
    fontWeight: 400,
  },
  browFilesBtn: {
    border: '1px solid #FFAD00',
    color: '#FFAD00',
    borderRadius: 8,
    padding: '5px 22px 7px',
    background: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  imagesDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginTop: 15,
    maxHeight: '280px',
    overflow: 'auto',
  },
  imagePreviewDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    borderRadius: 12,
    border: '1px solid #E1E4EA',
    position: 'relative',
  },
  uploadedImagesDiv: {
    display: 'flex',
    // flexDirection: 'column',
    gap: 10,
    marginTop: 15,
    maxHeight: '280px',
    // overflow: 'auto',
    flexWrap: 'wrap',
  },
  uploadedImagePreviewDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    borderRadius: 12,
    border: '1px solid #E1E4EA',
    position: 'relative',
    width: '43%',
  },
  imagePreviewImg: {
    width: '60px',
    height: '60px',
    borderRadius: 8,
    objectFit: 'cover',
  },
  imageRemoveIcon: {
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  uploadedImageRemoveIcon: {
    color: '#fb3748',
    gap: 5,
    display: 'flex',
    padding: '4px 10px 0px',
    transition: 'all ease 0.3s',
    cursor: 'pointer',
    borderRadius: 5,
    '& svg': {
      width: 24,
      height: 24,
    },
    '&:hover': {
      background: '#ececec',
    },
  },
  uploadedImageViewIcon: {
    color: '#335cff',
    gap: 5,
    display: 'flex',
    padding: '4px 10px 0px',
    transition: 'all ease 0.3s',
    borderRadius: 5,
    cursor: 'pointer',
    '& svg': {
      width: 24,
      height: 24,
    },
    '&:hover': {
      background: '#ececec',
    },
  },
  UploadeImageIcons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  imagePreviewInfoSection: {
    display: 'flex',
    gap: 15,
    width: '100%',
  },
  imagePreviewInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: '#999999',
    width: '100%',
  },
  imagePreviewName: {
    display: 'inline-black',
    // overflow: 'hidden',
    maxWidth: '400px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: '#131517',
    fontWeight: 600,
  },
  UploadedmagePreviewName: {
    display: 'inline-black',
    overflow: 'hidden',
    maxWidth: '190px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: '#131517',
    fontSize: '12px',
  },
  numberCounterTitle: {
    paddingRight: '10px',
    fontSize: '12px',
  },
  numbercounter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '18px',
  },
  numberbutton: {
    width: '30px',
    minWidth: '30px',
    height: '30px',
    border: '1px solid #000000',
    borderRadius: '6px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '18px',
    color: '#000000',
    transition: 'background-color 0.2s',
  },
  numberbuttonDisable: {
    width: '30px',
    minWidth: '30px',
    height: '30px',
    border: '1px solid #99A0AE',
    borderRadius: '6px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '18px',
    color: '#99A0AE',
    transition: 'background-color 0.2s',
  },
  numbervalue: {
    fontWeight: 'bold',
  },

  closeP: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },
  msgPopupBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  numberSelectDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '98.5%',
    padding: '0px 10px',
  },

  numberSelectSpan: {
    display: 'flex',
    justifyContent: 'flex-start',
    border: '1px solid #E8E8E8',
    borderRadius: 10,
    padding: '4px 12px',
    boxShadow: '0px 1px 2px 0px rgba(14, 18, 27, 0.03)',
  },
  select: {
    width: '100%',
    fontFamily: 'Averta Light',
    borderRadius: 8,
    '& > div': {
      // border: 'solid 1px #707070',
      minHeight: 32,
      borderRadius: 8,
      // height: 29,
      // flexWrap: 'nowrap',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
      height: 32,
    },
    '& > div:first-child': {
      background: 'transparent',
    },
    // '& svg': {
    //   fill: '#f8b100',
    // },
    '& input': {
      '&:disabled': {
        background: '#F5F7FA',
        color: '#CACFD8',
        border: 'transparent',
      },
    },
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    '& input': {
      width: '100%',
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
      height: 28.5,
      border: 'none',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },
  loc: {
    '& svg': {
      fill: '#f8b100',
    },

    fontFamily: 'Averta Light',
    width: '100%',
    height: 29,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      // border: 'solid 1px #707070',
      width: 'auto',
      textAlign: 'justify',
      minWidth: '-webkit-fill-available',
      maxWidth: '320px',
      minHeight: 29,
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
      height: 29,
      height: 29,
    },
  },

  imageUploadBtn: {
    color: '#ffffff !important',
    background: '#f8b100',
    padding: 5,
    // marginTop: '10px',
    margin: '2px',
    width: '95px',
    lineHeight: '1.3rem',
    '&:hover': {
      background: '#ce9300',
      color: '#ffffff',
    },
    '&:disabled': {
      background: '#e4e4e4',
      color: '#bcbcbc !important',
    },
  },

  saveBtn: {
    color: '#ffffff',
    background: '#0074F8',
    padding: 8,
    marginTop: '10px',
    margin: '2px',
    width: '95px',
    lineHeight: '1.3rem',
    '&:hover': {
      background: '#0057b9',
      color: '#ffffff',
    },
  },
  preApproveBtn: {
    color: '#ffffff',
    padding: '7px 40px',
    border: '1px solid rgba(82, 88, 102, 0.50)',
    borderRadius: 10,
    marginTop: '8px',
    margin: '2px',
    lineHeight: '1.3rem',
    background:
      'linear-gradient(0deg, #0E121B 55.7%, rgba(255, 255, 255, 0.00) 736.46%)',
    boxShadow:
      '0px 0px 0px 1px #242628, 0px 1px 2px 0px rgba(27, 28, 29, 0.48)',
    '& span>div>div': {
      display: 'flex',
      gap: 10,
      '& div>div': {
        display: 'flex',
      },
    },
    '&:disabled': {
      // border: '1px solid #F5F7FA',
      boxShadow: 'none',
      background: '#F5F7FA',
      color: '#CACFD8',
    },
  },
  cunfirmBtn: {
    color: '#1FC16B',
    padding: '6px 18px',
    border: '1px solid #1FC16B',
    borderRadius: 10,
    marginTop: '8px',
    margin: '2px',
    lineHeight: '1.3rem',
    background: 'transparent',
    '& span>div>div': {
      display: 'flex',
      gap: 10,
      '& div>div': {
        display: 'flex',
      },
    },
  },
  declineBtn: {
    color: '#FB3748',
    padding: '6px 20px',
    border: '1px solid #FB3748',
    borderRadius: 10,
    marginTop: '8px',
    margin: '2px',
    lineHeight: '1.3rem',
    background: 'transparent',
    '& span>div>div': {
      display: 'flex',
      gap: 10,
      '& div>div': {
        display: 'flex',
      },
    },
  },

  submitBtn: {
    color: '#000',
    background: '#FFAD00',
    borderRadius: 8,
    padding: '8px 15px',
    marginTop: '10px',
    margin: '2px',
    border: '1px solid #FFAD00',
    width: 'max-content',
    lineHeight: '1.3rem',
    // '&:hover': {
    //   background: '#0057b9',
    //   color: '#ffffff',
    // },
  },
  cancelBtn: {
    color: '#000',
    background: 'transparent',
    borderRadius: 8,
    padding: '6px 20px',
    border: '1px solid #000',
    marginTop: '10px',
    margin: '2px',
    width: 'max-content',
    lineHeight: '1.3rem',
    // '&:hover': {
    //   background: '#0057b9',
    //   color: '#ffffff',
    // },
  },
  confirmedText: {
    color: '#1FC16B',
    background: '#E8F8F0',
    borderRadius: 50,
    padding: '6px 15px',
  },
  declinedText: {
    color: '#FB3748',
    background: '#FECFD3',
    borderRadius: 60,
    padding: '5px 15px',
  },
  boxContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
  },
  imagesForUpload: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    padding: '25px',
  },
  box: {
    width: '100px',
    height: '120px',
    border: '1px dashed #ccc',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  addIcon: {
    width: '100%',
    height: '100%',
    fontSize: '24px',
    lineHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxPreview: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '5px',
  },
  zoomIcon: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    top: 0,
    height: '75%',
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '5px',
    cursor: 'pointer',
    display: 'none',
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 1000,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    margin: 'auto',
    display: 'block',
    maxHeight: '80vh',
  },
  addBox: {
    width: '100px',
    height: '100px',
    border: '1px dashed #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    color: '#888',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#555',
    },
  },

  imagePopup: {
    display: 'flex',
    background: '#d1d1d1',
    borderRadius: 8,
    position: 'relative',
    padding: '40px 15px 15px',
  },
  close: {
    position: 'absolute',
    top: '-5px',
    right: '25px',
    color: 'red',
    fontSize: '35px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  uploadBtn: {
    color: '#ffffff',
    background: '#f8b100',
    padding: '5px',
    margin: '2px',
    width: '95px',
    lineHeight: '1.3rem',
    '&:hover': {
      background: '#ce9300',
      color: '#ffffff',
    },
    '&:disabled': {
      background: '#e4e4e4',
      color: '#bcbcbc',
    },
  },

  containerTime: {
    position: 'relative',
    width: 'fit-content',
  },
  inputTime: {
    padding: '10px 15px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '14px',
    width: '200px',
    textAlign: 'center',
  },
  popupTime: {
    position: 'absolute',
    top: '50px',
    left: '0',
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#fff',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
  },
  sectionTime: {
    width: '150px',
  },
  labelTime: {
    display: 'block',
    fontSize: '14px',
    marginBottom: '5px',
  },
  timePickerTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '5px',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
  buttonTime: {
    background: 'transparent',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
  amPmButtonTime: {
    background: 'transparent',
    border: 'none',
    fontSize: '14px',
    padding: '5px',
    cursor: 'pointer',
  },
  hourTextTime: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  setTimeButtonTime: {
    marginTop: '10px',
    padding: '8px 20px',
    border: 'none',
    background: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    fontSize: '14px',
    cursor: 'pointer',
  },
}));
