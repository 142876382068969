import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    // backgroundColor: '#2b2b2b',
    display: 'flex',
    borderRadius: 8,
    border: '1px solid #E1E4EA',
    padding: '5px 10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '-webkit-fill-available',
    // margin: '0 15px',
    boxSizing: 'border-box',
  },

  icon: {
    height: 30,
    width: 30,
    color: '#fff',
    transition: 'all .3s ease-in',
    '&:hover': {
      color: '#f8b100',
    },
  },
  emoji: {
    height: 30,
    width: 30,
    color: ({ emoji }) => (emoji ? '#f8b100' : '#ccc'),
    cursor: 'pointer',
    transition: 'all .3s ease-in',
    '&:hover': {
      color: '#f8b100',
    },
  },
  sendMessageBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F5F7FA',
    width: '60px',
    borderRadius: 8,
  },
  sendMessageActiveBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFAD00',
    width: '60px',
    borderRadius: 8,
  },
  camera: {
    // backgroundImage: 'linear-gradient(to bottom, #007ef4, #2a75bc)',
    height: 40,
    width: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  input: {
    flex: '1',
    // width: 215,
    fontSize: 13,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.6,
    color: '##303030',
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
  },
  chat: {
    display: ({ emoji }) => (emoji ? 'block' : 'none'),
    position: 'absolute',
    bottom: 0,
    left: -260,
  },
}));
