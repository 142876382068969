import React, { useEffect } from 'react';
import { useNotify } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  getSupportNewChatByChatId,
  getSupportNewChat,
  getAdminNewChat,
  getChat,
  RejectOrder,
  delReq,
  SaveData,
  seenChat,
  RejectExport,
  delReqOrder,
} from '@/actions';
import { pushClient, _get, noScroll, removeNoScroll } from '@/actions/Helper';

import Chat from '../Chat';
import Input from '../Input';
import Header from '../ChatHeader';
import Dialog from '../Dialog';
import PopUp from './PopUp';

import useStyles from './styles';
import InputChatInbox from '../inputChatInbox';

const parseData = (data, user, userone, usertwo) => {
  return data.map((i) => ({
    message: i.message,
    time: i.humans_time,
    me: i.user_id === user.id,
    name: _get(i, 'sender.name', ''),
    avatar: _get(i, 'sender.image', ''),
    users: { userone: userone, usertwo: usertwo },
    id: i.id,
    mode: data.mode,
    message_type: i?.message_type,
  }));
};

export default ({
  otherPart,
  ChatDataParse,
  orderStatus,
  userone,
  usertwo,
  chatType,
}) => {
  const cls = useStyles();

  const listRef = React.useRef();
  const notify = useNotify();
  const [pusher, setPusher] = React.useState(null);
  const [state, setState] = React.useState({
    headerData: {},
    DATA: [],
    loading: true,
    popUp: false,
  });
  const user = useSelector((state) => state.reducer.user);
  const ChatData = useSelector(
    (state) => state.reducer.Chat || ChatDataParse || {}
  );
  const refresh = useSelector((state) => state.reducer.refreshChat || null);

  //recive message
  const handlePush = React.useCallback(
    (res) => {
      // auto seen
      if (user.id !== res.user_id) {
        console.log('seenchat');

        const { conversation_id, id } = res;
        seenChat(conversation_id, id);
        setState(({ DATA = [], otherPart }) => ({
          otherPart,
          DATA: DATA.concat(parseData([res], user)),
        }));
      }
    },
    [user]
  );

  const { type, id, userId, chatId, messageId, orderId, mode } = ChatData;

  const RefreshData = React.useCallback(() => {
    setState(() => ({ loading: true }));
    if (type === 'adminNewChat') {
      getAdminNewChat(userId).then(({ ls, chn, headerData }) => {
        setPusher(pushClient(chn, handlePush));
        setState(() => ({
          headerData,
          DATA: parseData(ls, user),
          loading: false,
        }));
      });
    } else if (type === 'support') {
      if (userId) {
        getSupportNewChat(userId).then(({ ls, chn, headerData }) => {
          setPusher(pushClient(chn, handlePush));
          setState(() => ({
            headerData,
            DATA: parseData(ls, user),
            loading: false,
          }));
        });
      } else if (chatId) {
        getSupportNewChatByChatId(chatId).then(
          ({ chatWithUser, ls, chn, headerData }) => {
            SaveData({
              Chat: {
                id: id,
                type: type,
                userId: chatWithUser.id,
                chatId: chatId,
              },
            });
            setPusher(pushClient(chn, handlePush));
            setState(() => ({
              headerData,
              DATA: parseData(ls, user, userone, usertwo),
              loading: false,
            }));
          }
        );
      }
    } else {
      getChat(id, mode, user).then(({ ls, chn, headerData, otherPart }) => {
        if (chatId && messageId) {
          seenChat(chatId, messageId);
        }
        setPusher(pushClient(chn, handlePush));
        setState((prev) => ({
          headerData,
          otherPart: prev.otherPart || otherPart,
          DATA: parseData(ls, user),
          loading: false,
        }));
      });
    }
  }, [user, setPusher, id, handlePush]);

  React.useEffect(() => {
    if (id || userId || chatId) RefreshData();
  }, [id, type, RefreshData]);

  React.useEffect(() => {
    if (refresh) {
      RefreshData();
      SaveData({ refreshChat: null });
    }
  }, [refresh, RefreshData]);

  React.useEffect(() => () => pusher && pusher.disconnect(), [pusher]);

  React.useEffect(() => {
    const { current } = listRef;
    // if (current) current.scrollTop = current.scrollHeight;
    let scroll_to_bottom = document.getElementById('chatslist');
    if (scroll_to_bottom && current) {
      scroll_to_bottom.scrollTop = current.scrollHeight;
    }
  }, [state.DATA]);

  const Reject = (id) => {
    setState(() => ({ loading: true }));
    let fun =
      type === 'request'
        ? mode === 'export'
          ? delReqOrder
          : delReq
        : mode === 'export'
        ? RejectExport
        : RejectOrder;

    fun(id)
      .then(({ msg }) => {
        SaveData({ Chat: null });
        notify(msg);
      })
      .catch((err) => notify(err, 'warning'));
  };

  const openPopUp = ({ message }) => {
    noScroll();
    setState({ ...state, popUp: message });
  };

  const closePopUp = () => {
    removeNoScroll();
    setState({ ...state, popUp: null });
  };

  return (
    <>
      {state.popUp && <PopUp data={state.popUp} Close={closePopUp} />}
      <div className={cls.card} id="RequestDialog">
        {state.loading ? (
          <div className={cls.loading}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Header
              type={
                type === 'adminNewChat' || type === 'support'
                  ? ChatData.user
                  : type
              }
              id={id ?? userId}
            />
            {(type === 'request' || type === 'order') && (
              <Dialog
                id={id ?? userId}
                Reject={Reject}
                notify={notify}
                type={type}
                mode={mode}
                data={state.headerData}
                otherPart={state.otherPart}
                setChat={setState}
                parseChatData={parseData}
                user={user}
              />
            )}
            <div
              id="chatslist"
              ref={listRef}
              className={clsx(
                'scroll-bar',
                cls.content,
                `${
                  type !== 'request' || type !== 'order' ? cls.bigContent : ''
                }`
              )}>
              {state.DATA.map((i, idx) => {
                // if (idx === 0 || (idx > 0 && i.id !== state.DATA[idx - 1].id)) {
                return (
                  <Chat
                    userone={userone}
                    usertwo={usertwo}
                    chatType={chatType}
                    orderStatus={orderStatus}
                    DATA={i}
                    key={idx}
                    popUp={openPopUp}
                  />
                );
                // }
              })}
            </div>
          </>
        )}
        {/* {type !== 'archive' && ( */}
        <div className={cls.inputContainer}>
          <InputChatInbox
            type={type}
            id={type === 'adminNewChat' || type === 'support' ? userId : id}
            notify={notify}
            setChat={setState}
            parseData={parseData}
            user={user}
            orderStatus={orderStatus}
            mode={mode}
          />
        </div>
        {/* )} */}
      </div>
    </>
  );
};
