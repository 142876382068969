import React, { useState, useRef, useEffect } from 'react';
import styles from './styles';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';

export default ({
  disabled,
  rowId,
  isOpen,
  setOpenRowId,
  times,
  setTimes,
  index,
  updateFormData,
}) => {
  const [fromTime, setFromTime] = useState({ hour: 0, amPm: 'AM' });
  const [toTime, setToTime] = useState({ hour: 0, amPm: 'AM' });
  const popupRef = useRef(null);
  const cls = styles();

  const handleInputClick = () => {
    setOpenRowId(isOpen ? null : rowId);
  };

  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setOpenRowId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const saveTimesAsDateTime = (updatedFromTime, updatedToTime) => {
    const currentDate = new Date();
    const fromHour =
      (updatedFromTime.hour % 12) + (updatedFromTime.amPm === 'PM' ? 12 : 0);
    const toHour =
      (updatedToTime.hour % 12) + (updatedToTime.amPm === 'PM' ? 12 : 0);

    const fromDateTime = new Date(currentDate);
    fromDateTime.setHours(fromHour, 0, 0, 0);

    const toDateTime = new Date(currentDate);
    toDateTime.setHours(toHour, 0, 0, 0);

    updateFormData(index, 'pickup_date_start', fromDateTime);
    updateFormData(index, 'pickup_date_end', toDateTime);

    if (toHour <= fromHour) {
      alert("Invalid range: 'To' time cannot be earlier than 'From' time.");
    }
  };

  React.useEffect(() => {
    const currentDate = new Date();

    const fromDateTime = new Date(currentDate);
    fromDateTime.setHours(7, 0, 0, 0);

    const toDateTime = new Date(currentDate);
    toDateTime.setHours(16, 0, 0, 0);

    updateFormData(index, 'pickup_date_start', fromDateTime);
    updateFormData(index, 'pickup_date_end', toDateTime);
  }, []);

  const incrementHour = (setter, time, otherTimeSetter, otherTime, type) => {
    const newHour = time.hour === 12 ? 1 : time.hour + 1;
    const updatedTime = { ...time, hour: newHour };
    setter(updatedTime);
    if (type === 'from') {
      saveTimesAsDateTime(updatedTime, otherTime);
    } else {
      saveTimesAsDateTime(otherTime, updatedTime);
    }
  };

  const decrementHour = (setter, time, otherTimeSetter, otherTime, type) => {
    const newHour = time.hour === 1 ? 12 : time.hour - 1;
    const updatedTime = { ...time, hour: newHour };
    setter(updatedTime);
    if (type === 'from') {
      saveTimesAsDateTime(updatedTime, otherTime);
    } else {
      saveTimesAsDateTime(otherTime, updatedTime);
    }
  };

  const toggleAmPm = (setter, time, otherTimeSetter, otherTime, type) => {
    const newAmPm = time.amPm === 'AM' ? 'PM' : 'AM';
    const updatedTime = { ...time, amPm: newAmPm };
    setter(updatedTime);
    if (type === 'from') {
      saveTimesAsDateTime(updatedTime, otherTime);
    } else {
      saveTimesAsDateTime(otherTime, updatedTime);
    }
  };

  return (
    <div className={cls.containerTime}>
      <input
        disabled={disabled}
        type="text"
        value={`${fromTime.hour}:00 ${fromTime.amPm} - ${toTime.hour}:00 ${toTime.amPm}`}
        onClick={handleInputClick}
        readOnly
        className={cls.inputTime}
      />

      {isOpen && (
        <div ref={popupRef} className={cls.popupTime}>
          <div className={cls.sectionTime}>
            <label className={cls.labelTime}>From</label>
            <div className={cls.timePickerTime}>
              <span className={cls.hourTextTime}>{fromTime.hour}:00</span>
              <span className={cls.arrowSpan}>
                <button
                  onClick={() =>
                    decrementHour(
                      setFromTime,
                      fromTime,
                      setToTime,
                      toTime,
                      'from'
                    )
                  }
                  className={cls.buttonTime}>
                  <ReactSVG
                    src={Icons.upVector}
                    className={cls.infoCircleIcon}
                  />
                </button>
                <button
                  onClick={() =>
                    incrementHour(
                      setFromTime,
                      fromTime,
                      setToTime,
                      toTime,
                      'from'
                    )
                  }
                  className={cls.buttonTime}>
                  <ReactSVG
                    src={Icons.downVector}
                    className={cls.infoCircleIcon}
                  />
                </button>
              </span>
              <button
                onClick={() =>
                  toggleAmPm(setFromTime, fromTime, setToTime, toTime, 'from')
                }
                className={cls.amPmButtonTime}>
                {fromTime.amPm}
              </button>
            </div>
          </div>

          <div className={cls.sectionTime}>
            <label className={cls.labelTime}>To</label>
            <div className={cls.timePickerTime}>
              <span className={cls.hourTextTime}>{toTime.hour}:00</span>
              <span className={cls.arrowSpan}>
                <button
                  onClick={() =>
                    decrementHour(
                      setToTime,
                      toTime,
                      setFromTime,
                      fromTime,
                      'to'
                    )
                  }
                  className={cls.buttonTime}>
                  <ReactSVG
                    src={Icons.upVector}
                    className={cls.infoCircleIcon}
                  />
                </button>
                <button
                  onClick={() =>
                    incrementHour(
                      setToTime,
                      toTime,
                      setFromTime,
                      fromTime,
                      'to'
                    )
                  }
                  className={cls.buttonTime}>
                  <ReactSVG
                    src={Icons.downVector}
                    className={cls.infoCircleIcon}
                  />
                </button>
              </span>
              <button
                onClick={() =>
                  toggleAmPm(setToTime, toTime, setFromTime, fromTime, 'to')
                }
                className={cls.amPmButtonTime}>
                {toTime.amPm}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
