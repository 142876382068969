// in src/MyLayout.js
import React, { useState } from 'react';
import { Layout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AppBar from './AppBar';
import SideBar from './SideBar';
import Menu from './Menu';
import ChatBar from '../Chat/ChatBar';
import Footer from './Footer';
import ContactInfoPopup from '../ContactInfoPopup';
import { usePermissions } from 'react-admin';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const styles = makeStyles({
  container: {
    margin: '1px 0px 90px 100px',
    padding: '0',
    minHeight: 'calc(90vh - 70px)',
    '@media (max-width:1000px)': {
      margin: '32px 10px 90px 100px',
    },
    '@media (max-width:800px)': {
      margin: '32px 10px 90px 10px',
    },
  },
  loginLoader: {
    width: '25px !important',
    height: '25px !important',
    color: '#ffffff',
  },
  adminBtn: {
    width: '140px',
    position: 'fixed',
    bottom: '30px',
    left: '15px',
    width: 'fit-content',
    background: '#f8b100',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    zIndex: 9999999999,
    '&:hover': {
      background: '#f8b100',
    },
  },
  adminContainer: {
    margin: '32px 0px 90px 100px !important',
    '@media (max-width:800px)': {
      margin: '32px 0px 90px 0px !important',
    },
  },
});

export default (props) => {
  const cls = styles();
  const [loginLoading, setLoginLoading] = useState(false);
  const popUp = useSelector((state) => state.reducer.ContactInfoPopup || null);
  const { permissions } = usePermissions();
  let history = useHistory();

  const handleRedirectToAdmin = () => {
    setLoginLoading(true);
    let adminToken = localStorage.getItem('token_admin');
    localStorage.setItem('token', adminToken);
    localStorage.setItem('permissions', 'admin');
    history.push('/dashboard');
    window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.children]);

  return (
    <Layout {...props} appBar={AppBar} sidebar={SideBar} menu={Menu}>
      {/* {permissions !== 'admin' && <ChatBar />} */}
      {permissions === 'user' && localStorage.getItem('token_admin') && (
        <Button
          className={cls.adminBtn}
          onClick={() => handleRedirectToAdmin()}>
          {loginLoading ? (
            <CircularProgress className={cls.loginLoader} />
          ) : (
            'Redirect to Admin'
          )}
        </Button>
      )}
      {popUp && <ContactInfoPopup data={popUp} />}
      <div
        className={`${cls.container} ${
          permissions === 'admin' && cls.adminContainer
        }`}>
        {/* {permissions === 'admin' && window.location.href.includes('members') && <AdminTopMenu />} */}
        {props.children}
      </div>
      <Footer />
    </Layout>
  );
};
