import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: ({ me }) => (!me ? 'row-reverse' : 'row'),
    alignItems: ({ me }) => (!me ? 'flex-end' : 'flex-start'),
    margin: '10px 0',
  },
  chat: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 12px',
    justifyContent: 'space-between',
    color: '#424346',
    width: '80%',
    boxShadow:
      '0px 0px 0.571px 0px rgba(66, 71, 76, 0.32), 0px 4.571px 6.857px 0px rgba(66, 71, 76, 0.08)',
    background: ({ me }) => (!me ? '#FFF' : '#FFFAEB'),

    borderRadius: ({ me }) => (!me ? '20px 0 20px 20px' : '0 20px 20px 20px'),
    border: ({ me }) => (!me ? '1.5px solid #FFECC0' : '1px solid #FFECC0'),
  },
  chatInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E8E8E8',
    paddingBottom: 8,
    marginBottom: 10,
  },
  chatUserInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  title: {
    fontSize: 12,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.6,
    color: '#424346',
    // marginBottom: 12,
    // wordBreak: 'break-all',
  },

  time: {
    fontSize: 13,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.3,
    color: '#99A0AE',
  },

  avatar: {
    height: 28,
    width: 28,
    // margin: ({ me }) => (me ? '0 0 0 5px' : '0 5px 0 0'),
  },
  img: {
    height: 200,
    borderRadius: 20,
    marginBottom: 10,
    objectFit: 'contain',
  },
  // chatLine: {
  //   display: 'block',
  // },
}));
