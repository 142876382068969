import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import useMediaQuery from 'use-mediaquery';
import { SaveData } from '@/actions';

const styles = makeStyles({
  drawerPaper: {
    position: 'relative',
    height: '100vh',
    overflowX: 'hidden',
    // width: ({ open }) => (open ? '8.333vw' : 50),
    width: '95px',
    backgroundColor: '#3A3A3A',
    // marginTop: 10,
    zIndex: 'inherit',
    // borderTopRightRadius: 5,
    // borderBottomRightRadius: 5,
    boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    // padding: ({ open }) => (open ? '10px 0px' : '20px 5px'),
    padding: '10px 0px',
    transition: 'all .2s ease-in-out 0s',
    '@media (max-width:800px)': {
      height: '100%',
      margin: '0',
      width: '100px',
    },
  },
  drawer: {
    position: 'fixed',
    zIndex: '3',
  },
});

export default ({ children, ...rest }) => {
  const cls = styles();
  const openUserMenu = useSelector(
    (state) => state.reducer.openUserMenu || null
  );

  const isSmallDevice = useMediaQuery('(max-width: 800px)');

  const handleCloseMenu = () => {
    SaveData({ openUserMenu: false });
  };

  return (
    <Drawer
      variant={!isSmallDevice ? 'permanent' : ''}
      open={!isSmallDevice ? true : openUserMenu}
      onClose={() => handleCloseMenu()}
      PaperProps={{ className: [cls.drawerPaper] }}
      className={cls.drawer}
      {...rest}>
      {children}
    </Drawer>
  );
};
