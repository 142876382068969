import React from 'react';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import closeIcon from '../../../assets/img/closeIcon.svg';
import {
  Modal,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { useOutsideAlerter } from '@/hocs';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import { TextareaAutosize } from '@mui/material';
import Select from 'react-select';
import {
  adminUploadHtmlToScrape,
  orderApprove,
  uploadContainerImages,
} from '@/actions';
import { Loading, useNotify } from 'react-admin';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';

export default ({ Close, orderID, RejectEmpty }) => {
  const cls = styles();
  const wrapperRef = React.useRef(null);
  // useOutsideAlerter(wrapperRef, () => {
  //   Close();
  // });
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);

  return (
    <div className={cls.popup}>
      <div className={cls.uploadImageCard}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.closeP} onClick={Close} />
        </Tooltip>
        <div ref={wrapperRef} className={cls.popupCard}>
          <span className={cls.tableTopActions}>
            <ReactSVG
              src={Icons.galleryAddBlack}
              className={cls.dicsounticon}
            />
            Decline Confirmation
          </span>
          <span>{''}</span>
        </div>
        <div className={cls.msgPopupBtns} style={{ marginTop: '20px' }}>
          <Button className={clsx(cls.cancelBtn)} onClick={() => Close()}>
            No, Cancel
          </Button>
          {loading ? (
            <Button className={clsx(cls.submitBtn)}>
              <CircularProgress />
            </Button>
          ) : (
            <Button
              className={clsx(cls.submitBtn)}
              onClick={() => RejectEmpty(orderID)}>
              Yes, Decline
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
