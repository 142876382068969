import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loading } from 'react-admin';
import { Button, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { _get, locationOpt } from '@/actions/Helper';
import styles from './styles';
import clsx from 'clsx';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from '@mui/material';
import TimePicker from 'rc-time-picker';
import { useNotify } from 'react-admin';
import { Request } from '@/actions';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';
import { useOutsideAlerter } from '@/hocs';
import TimePickerFromTo from '@/components/Chat/TimePickerFromTo';
import ReservationTimePicker from '@/components/Chat/ReservationTimePicker';

export default ({ CloseReserve, item, query }) => {
  const cls = styles();
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    CloseReserve();
  });

  const history = useHistory();
  const [openClock, setOpenClock] = React.useState(null); // Manage which clock's popup is open
  const [loading, setLoading] = useState(false);
  const [fromTime, setFromTime] = React.useState({ hour: 7, amPm: 'AM' });
  const [toTime, setToTime] = React.useState({ hour: 5, amPm: 'PM' });
  const [times, setTimes] = React.useState([]);
  const [form, setForm] = useState({
    inventory_id: item.id,
    count:
      query.count <= item.count_available ? query.count : item.count_available,
    chase_type: item.chase_type,
    size_type: item.size,
    movement: '',
    hoursLimit: '',
    location: null,
    startTime: '',
    endTime: '',
    instructions: '',
    pictureNeeded: '',
  });
  const [error, setError] = useState('');
  const notify = useNotify();
  const [state, setState] = React.useState({
    loading: true,
    DATA: [],
    RemNum: 0,
  });

  const handleRadioChange = (field) => (event) => {
    const value = event.target.value;
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (field === 'movement') {
      setError(''); // Clear error when changing movement
    }
  };

  const handleInputChange = (field) => (value) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  // console.log('query', query);

  const handleSubmit = () => {
    const formattedData = {
      ...form,
      startTime: form.startTime
        ? moment(form.startTime).format('h:mm a')
        : null,
      endTime: form.endTime ? moment(form.endTime).format('h:mm a') : null,
    };
    console.log('submit', formattedData);

    if (!form.movement) {
      notify('Please fill in all the fields.', 'warning');
      setError('movment');
    } else if (form.movement === 'dropOff' && !form.location) {
      setError('location');
      notify('Please fill in all the fields.', 'warning');
    } else if (
      form.hoursLimit === 'custom' &&
      !form.startTime &&
      !form.endTime
    ) {
      notify('Please fill in all the fields.', 'warning');
      setError('timeLimit');
    } else if (!form.pictureNeeded) {
      notify('Please fill in all the fields.', 'warning');
      setError('picture');
    } else {
      setState({ ...state, loading: true });
      if (item.count_available > state.RemNum)
        item.count_available = state.RemNum;

      Request(formattedData)
        .then((res) => {
          let RemNum = state.RemNum - item.count_available;
          if (RemNum < 0) RemNum = 0;
          let DATA = state.DATA.filter((i) => i.id !== item.id);
          setState({ DATA, RemNum, loading: false });
          notify(res);
          if (RemNum === 0) {
            history.push('/inbox/request');
          }
        })
        .catch((err) => {
          notify(err, 'warning');
          setState({ ...state, loading: false });
        });
      // console.log('formattedData', formattedData);
    }
  };

  const requiredFields = form.movement === 'dropOff';
  const showLocationField =
    form.movement === 'dropOff' || form.movement === 'flexible';
  const showStartEndTimes = form.hoursLimit === 'custom';
  console.log(error, 'error');

  return (
    <div
      id="app_modal"
      onClick={(e) => e.target.id === 'app_modal' && CloseReserve()}
      className={cls.popup}>
      <div className={cls.cardP}>
        {loading ? (
          <Loading className={cls.loading} />
        ) : (
          <>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded className={cls.closeP} onClick={CloseReserve} />
            </Tooltip>
            <div ref={wrapperRef} className={cls.popupCard}>
              <span className={cls.tableTopActions}>
                <ReactSVG
                  src={Icons.clipboardTick}
                  className={cls.dicsounticon}
                />
                Reservation form
              </span>
              <span>
                Dear user, in order to reserve the available containers, you
                will first have to fill in the following form
              </span>
              <div className={cls.formSection}>
                <FormControl className={cls.radioBtns}>
                  <FormLabel className={cls.labelFirst}>
                    Movement Options:
                    {error === 'movment' ? (
                      <>
                        <span className={cls.requiredField}>*</span>
                        <span className={cls.requiredFieldText}>Required</span>
                      </>
                    ) : (
                      ''
                    )}
                  </FormLabel>
                  <RadioGroup
                    className={cls.radioMovment}
                    onChange={handleRadioChange('movement')}
                    name="movement-options">
                    <FormControlLabel
                      value="pickup"
                      control={<Radio />}
                      label={
                        <span className={cls.withInfoLabel}>
                          <span>Pickup:</span> We will pickup the empty
                          containers.
                          <Tooltip
                            arrow
                            title="Pickup: We will pickup the empty containers."
                            placement="top">
                            <ReactSVG
                              src={Icons.infoCircleBlack}
                              className={cls.dicsounticon}
                            />
                          </Tooltip>
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="dropOff"
                      control={<Radio />}
                      label="Drop off: You need to drop off the empties at our location."
                    />
                    <FormControlLabel
                      value="flexible"
                      control={<Radio />}
                      label="Flexible: Either Pickup or Drop Off works for us."
                    />
                  </RadioGroup>
                  {showLocationField && (
                    <>
                      <FormLabel className={cls.labelFirst}>
                        Dropoff Location:
                        {error === 'location' ? (
                          <>
                            <span className={cls.requiredField}>*</span>
                            <span className={cls.requiredFieldText}>
                              Required
                            </span>
                          </>
                        ) : (
                          ''
                        )}
                      </FormLabel>
                      <div className={`${cls.input} ${cls.containerInput}`}>
                        <TextField
                          type="text"
                          // key={index}
                          placeholder={`Enter your dropoff location`}
                          // className={cls.input}
                          // disabled={container.status === 2}
                          // value={container.name}
                          // onChange={(e) =>
                          // handleUpdateContainers(
                          //   'name',
                          //   e.target.value,
                          //   index
                          // )
                          // }
                          onChange={(e) =>
                            handleInputChange('location')(e.target.value)
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                <ReactSVG
                                  src={Icons.locationInput}
                                  className={cls.dicsounticon}
                                />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                        />
                      </div>
                      {/* <Grid item xs={4}>
                        <AsyncSelect
                          placeholder="Location"
                          className={cls.loc}
                          value={form.location}
                          loadOptions={locationOpt}
                          onChange={(item) =>
                            handleInputChange('location')(item)
                          }
                          cacheOptions
                          defaultOptions
                        />
                      </Grid> */}
                    </>
                  )}
                </FormControl>
                <FormControl className={cls.radioBtns}>
                  <FormLabel className={cls.labelFirst}>Hours Limit:</FormLabel>
                  <RadioGroup
                    row
                    onChange={handleRadioChange('hoursLimit')}
                    name="hours-limit-options">
                    <FormControlLabel
                      value="noLimit"
                      control={<Radio />}
                      label="No Limit"
                    />
                    <FormControlLabel
                      value="today"
                      control={<Radio />}
                      label="Today"
                    />
                    <FormControlLabel
                      value="custom"
                      control={<Radio />}
                      label="Custom"
                    />
                  </RadioGroup>
                </FormControl>
                {showStartEndTimes && (
                  <>
                    <FormControl className={cls.radioBtns}>
                      <FormLabel className={cls.labelFirst}>
                        Time
                        {error === 'timeLimit' ? (
                          <>
                            <span className={cls.requiredField}>*</span>
                            <span className={cls.requiredFieldText}>
                              Required
                            </span>
                          </>
                        ) : (
                          ''
                        )}
                      </FormLabel>
                      <Grid item xs={12}>
                        <ReservationTimePicker
                          // disabled={item.selectedContainer ? false : true}
                          // rowId={item.id}
                          isOpen={openClock}
                          setOpenClock={setOpenClock}
                          toTime={toTime}
                          setToTime={setToTime}
                          fromTime={fromTime}
                          setFromTime={setFromTime}
                          times={times}
                          setTimes={setTimes}
                          index={0}
                          handleInputChange={handleInputChange}
                          // onChange={(e) =>
                          //   updateFormData(index, 'fromTime', times)
                          // }
                        />
                      </Grid>
                    </FormControl>
                  </>
                )}
                <FormControl className={cls.radioBtns}>
                  <FormLabel className={cls.labelFirst}>
                    Picture Needed:
                    {error === 'picture' ? (
                      <>
                        <span className={cls.requiredField}>*</span>
                        <span className={cls.requiredFieldText}>Required</span>
                      </>
                    ) : (
                      ''
                    )}
                  </FormLabel>
                  <RadioGroup row onChange={handleRadioChange('pictureNeeded')}>
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl className={cls.radioBtns}>
                  <FormLabel className={cls.labelFirst}>
                    Have an instruction:
                  </FormLabel>
                  <TextareaAutosize
                    className={cls.inputTextArea}
                    value={form.instructions}
                    onChange={(e) =>
                      handleInputChange('instructions')(e.target.value)
                    }
                    minRows={4}
                    maxRows={4}
                    maxLength={200}
                  />
                </FormControl>
              </div>

              <span className={cls.msgPopupBtns}>
                <Button
                  className={clsx(cls.cancelBtn)}
                  onClick={(e) => {
                    CloseReserve();
                  }}>
                  Cancel
                </Button>
                <Button
                  className={clsx(cls.submitBtn)}
                  onClick={() => {
                    handleSubmit();
                  }}>
                  Submit
                </Button>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
