import React from 'react';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import { IconButton, CircularProgress } from '@material-ui/core';
import { Tooltip, Button } from '@material-ui/core';
import clsx from 'clsx';
import { uploadContainerApproval, uploadContainerImages } from '@/actions';
import { Loading, useNotify } from 'react-admin';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';

export default ({
  Close,
  DATA,
  hasImage,
  orderStatus,
  chatType,
  orderID,
  containerID,
  index,
}) => {
  const cls = styles();
  const wrapperRef = React.useRef(null);
  // useOutsideAlerter(wrapperRef, () => {
  //   Close();
  // });
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [uploadedImages, setUploadedImages] = React.useState([]);
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });
  const [draggedFileName, setDraggedFileName] = React.useState('');
  const [isDragging, setIsDragging] = React.useState(false);

  console.log('index', index);
  console.log(
    'DATA?.inventory?.containers[index]?.images',
    DATA?.inventory?.containers[index].images
  );
  React.useEffect(() => {
    if (hasImage) {
      console.log('data', DATA);
      console.log('hasImage', hasImage);

      setUploadedImages(
        hasImage === 'hasImage'
          ? images
          : hasImage === 'withImage'
          ? DATA?.inventory?.containers[index].images
          : hasImage === 'preApprovedImage'
          ? DATA?.preapprovedcontainer?.container.images
          : DATA?.inventory?.containers[index].images
      );
    }
  }, [hasImage]);

  console.log('uploadedImages', uploadedImages);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setDraggedFileName('file.name');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    if (!files.length) return;

    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'pdf',
      'image/gif',
    ];

    const validFiles = files.filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        notify('You can only upload image files.', 'warning');
        return false;
      }
      if (file.size > 2000000) {
        notify('You can upload IMG files less than 2MB.', 'warning');
        return false;
      }
      return true;
    });

    if (validFiles.length + images.length + uploadedImages?.length > 5) {
      notify('You can upload up to 5 images only.', 'warning');
      return;
    }

    const newImages = validFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      size: file.size,
      progress: 0,
    }));

    setImages((prevImages) => [...prevImages, ...newImages]);

    if (validFiles.length > 0) {
      setDraggedFileName(validFiles.map((file) => file.name).join(', '));
    }
  };

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  const handleUpload = () => {
    if (images.length > 0) {
      setLoading(true);

      images.forEach((image, index) => {
        startUpload(index);
      });

      const payload = {
        container_id: DATA?.selectedContainerID,
        images,
      };

      uploadContainerImages(payload)
        .then((res) => {
          notify(res.message, 'success');
          setLoading(false);
          Close();
        })
        .catch((err) => {
          notify(err.message || 'Error when upload', 'warning');
          setLoading(false);
        });
    } else {
      notify('Select Image(s) to upload', 'warning');
    }
  };
  const handleUploadApproval = () => {
    if (images.length > 0) {
      setLoading(true);

      images.forEach((image, index) => {
        startUpload(index);
      });
      console.log('orderID', orderID);

      const payload = {
        orderID,
        container_id: containerID,
        images,
      };

      uploadContainerApproval(payload)
        .then((res) => {
          notify(res.message, 'success');
          setLoading(false);
          Close();
        })
        .catch((err) => {
          notify(err.message || 'Error when upload', 'warning');
          setLoading(false);
        });
    } else {
      notify('Select File(s) to upload', 'warning');
    }
  };

  const handleAddImage = (event) => {
    const files = Array.from(event.target.files);
    if (!files.length) return;

    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/gif',
      'pdf',
      'image/svg+xml',
    ];

    const validFiles = files.filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        notify('You can only upload image files.', 'warning');
        return false;
      }
      if (file.size > 2000000) {
        notify('You can upload IMG files less than 2MB.', 'warning');
        return false;
      }
      return true;
    });

    if (validFiles.length + images.length + uploadedImages.length > 5) {
      notify('You can upload up to 5 images only.', 'warning');
      return;
    }

    const newImages = validFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      size: file.size,
      progress: 0,
    }));

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const startUpload = (index) => {
    const speedPerSecond = 3000;
    const fileSize = images[index]?.size || 0;
    const duration = speedPerSecond;
    const step = 100 / (duration / 200);

    const interval = setInterval(() => {
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        const currentProgress = updatedImages[index]?.progress || 0;

        if (currentProgress >= 100) {
          clearInterval(interval);
        } else {
          updatedImages[index].progress = Math.min(currentProgress + step, 100);
        }

        return updatedImages;
      });
    }, 200);
  };

  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes} B`;
    } else if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(0)} KB`;
    } else {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    }
  };

  return (
    <div className={cls.popup}>
      <div className={cls.uploadImageCard}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.closeP} onClick={Close} />
        </Tooltip>
        <div ref={wrapperRef} className={cls.popupCard}>
          <span className={cls.tableTopActions}>
            <ReactSVG
              src={Icons.galleryAddBlack}
              className={cls.dicsounticon}
            />
            {hasImage === 'approvalFile'
              ? 'Approval Upload'
              : 'Container Image Upload'}
          </span>
          <span>
            {hasImage === 'approvalFile'
              ? 'You will need to upload your approval file to complete the confirmation process.'
              : 'Add your pictures here, and you can upload up to 5 pictures max.'}
          </span>
          {hasImage === 'approvalFile' ? null : (
            <div className={cls.uploadedImagesDiv}>
              {uploadedImages?.map((image, index) => (
                <div key={index} className={cls.uploadedImagePreviewDiv}>
                  <span className={cls.imagePreviewInfoSection}>
                    <img
                      src={image.imgSrc}
                      alt={`Preview ${index}`}
                      className={cls.imagePreviewImg}
                    />
                    <span className={cls.imagePreviewInfo}>
                      <Tooltip arrow title={image?.image_name} placement="top">
                        <span className={cls.UploadedmagePreviewName}>
                          {image?.image_name}
                        </span>
                      </Tooltip>
                      <span className={cls.UploadeImageIcons}>
                        {chatType === 'offer' &&
                        (orderStatus.value === '1' ||
                          orderStatus.value === '4') ? (
                          <span
                            // onClick={() => handleRemoveImage(index)}
                            className={cls.uploadedImageRemoveIcon}>
                            <ReactSVG src={Icons.xCircle} />
                            Delete
                          </span>
                        ) : (
                          ''
                        )}
                        <span
                          onClick={() => window.open(image?.imgSrc, '_blank')}
                          className={cls.uploadedImageViewIcon}>
                          <ReactSVG src={Icons?.imageView} />
                          View
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              ))}
            </div>
          )}
          {hasImage === 'preApprovedImage' || hasImage === 'approvalFile' ? (
            ''
          ) : hasImage === 'hasImage' || uploadedImages?.length <= 5 ? (
            <>
              <div
                className={cls.imageUploadSection}
                // onDragOver={(e) => e.preventDefault()}
                // onDragLeave={() => setIsDragging(false)}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                // onDrop={handleDrop}
                onMouseMove={handleMouseMove}
                style={{
                  // border: isDragging ? '2.5px dashed #FFE097' : '2px dashed #ccc',
                  // padding: '20px',
                  // borderRadius: '8px',
                  // textAlign: 'center',
                  // cursor: 'pointer',
                  backgroundColor: isDragging ? '#FEF7E8' : '#fff',
                  transition: 'background-color 0.2s ease',
                }}>
                <ReactSVG
                  src={Icons.imageUpload}
                  className={cls.imageUploadIcon}
                />
                <span className={cls.imageUploadArea}>
                  <span className={cls.dragDropText}>
                    Drag & Drop your files
                  </span>
                  <span className={cls.dragDropOrText}>
                    <span className={cls.orHr} />
                    <span>OR</span>
                    <span className={cls.orHr} />
                  </span>
                  <div>
                    <label className={cls.browFilesBtn} htmlFor="file-input">
                      Browse files
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      multiple
                      style={{ display: 'none' }}
                      onChange={handleAddImage}
                    />
                  </div>
                </span>
              </div>
              <span className={cls.fileTypeInfo}>
                Only support .jpg, .png and zip files less then 2MB
              </span>
              <div className={cls.imagesDiv}>
                {images.map((image, index) => (
                  <div key={index} className={cls.imagePreviewDiv}>
                    <span className={cls.imagePreviewInfoSection}>
                      <img
                        src={image.preview}
                        alt={`Preview ${index}`}
                        className={cls.imagePreviewImg}
                      />
                      <span className={cls.imagePreviewInfo}>
                        <Tooltip
                          arrow
                          title={image?.file?.name}
                          placement="top">
                          <span className={cls.imagePreviewName}>
                            {image?.file?.name}
                          </span>
                        </Tooltip>
                        <span>{formatFileSize(image?.file?.size)}</span>
                        <div
                          style={{
                            width: '100%',
                            height: '5px',
                            backgroundColor: '#ddd',
                            borderRadius: '3px',
                            position: 'relative',
                            overflow: 'hidden',
                          }}>
                          <div
                            style={{
                              width: `${image.progress}%`,
                              height: '450px',
                              background: `linear-gradient(to right, #3b82f6,rgb(168, 206, 253))`,
                              transition: 'width 0.2s linear',
                            }}
                          />
                        </div>
                      </span>
                    </span>
                    <IconButton
                      onClick={() => handleRemoveImage(index)}
                      className={cls.tickIcon}>
                      <ReactSVG
                        src={Icons.xCircle}
                        className={cls.imageRemoveIcon}
                      />
                    </IconButton>
                  </div>
                ))}
              </div>
              {isDragging && draggedFileName && (
                <div
                  style={{
                    position: 'fixed',
                    bottom: mousePosition.y + 10,
                    left: mousePosition.x + 10,
                    backgroundColor: '#FEF7E8',
                    border: '1px solid #FFAD00',
                    color: '#131517',
                    padding: '8px 12px',
                    borderRadius: '50px',
                    zIndex: 1000,
                    pointerEvents: 'none',
                    fontSize: '14px',
                  }}>
                  {draggedFileName}
                </div>
              )}{' '}
            </>
          ) : (
            ''
          )}
          {hasImage === 'approvalFile' ? (
            <>
              <div
                className={cls.imageUploadSection}
                // onDragOver={(e) => e.preventDefault()}
                // onDragLeave={() => setIsDragging(false)}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                // onDrop={handleDrop}
                onMouseMove={handleMouseMove}
                style={{
                  // border: isDragging ? '2.5px dashed #FFE097' : '2px dashed #ccc',
                  // padding: '20px',
                  // borderRadius: '8px',
                  // textAlign: 'center',
                  // cursor: 'pointer',
                  backgroundColor: isDragging ? '#FEF7E8' : '#fff',
                  transition: 'background-color 0.2s ease',
                }}>
                <ReactSVG
                  src={Icons.imageUpload}
                  className={cls.imageUploadIcon}
                />
                <span className={cls.imageUploadArea}>
                  <span className={cls.dragDropText}>
                    Drag & Drop your files
                  </span>
                  <span className={cls.dragDropOrText}>
                    <span className={cls.orHr} />
                    <span>OR</span>
                    <span className={cls.orHr} />
                  </span>
                  <div>
                    <label className={cls.browFilesBtn} htmlFor="file-input">
                      Browse files
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      multiple
                      style={{ display: 'none' }}
                      onChange={handleAddImage}
                    />
                  </div>
                </span>
              </div>
              <span className={cls.fileTypeInfo}>
                Only support .jpg, .png and pdf files less then 3MB
              </span>
              <div className={cls.imagesDiv}>
                {images.map((image, index) => (
                  <div key={index} className={cls.imagePreviewDiv}>
                    <span className={cls.imagePreviewInfoSection}>
                      <img
                        src={image.preview}
                        alt={`Preview ${index}`}
                        className={cls.imagePreviewImg}
                      />
                      <span className={cls.imagePreviewInfo}>
                        <Tooltip
                          arrow
                          title={image?.file?.name}
                          placement="top">
                          <span className={cls.imagePreviewName}>
                            {image?.file?.name}
                          </span>
                        </Tooltip>
                        <span>{formatFileSize(image?.file?.size)}</span>
                        <div
                          style={{
                            width: '100%',
                            height: '5px',
                            backgroundColor: '#ddd',
                            borderRadius: '3px',
                            position: 'relative',
                            overflow: 'hidden',
                          }}>
                          <div
                            style={{
                              width: `${image.progress}%`,
                              height: '450px',
                              background: `linear-gradient(to right, #3b82f6,rgb(168, 206, 253))`,
                              transition: 'width 0.2s linear',
                            }}
                          />
                        </div>
                      </span>
                    </span>
                    <IconButton
                      onClick={() => handleRemoveImage(index)}
                      className={cls.tickIcon}>
                      <ReactSVG
                        src={Icons.xCircle}
                        className={cls.imageRemoveIcon}
                      />
                    </IconButton>
                  </div>
                ))}
              </div>
              {isDragging && draggedFileName && (
                <div
                  style={{
                    position: 'fixed',
                    bottom: mousePosition.y + 10,
                    left: mousePosition.x + 10,
                    backgroundColor: '#FEF7E8',
                    border: '1px solid #FFAD00',
                    color: '#131517',
                    padding: '8px 12px',
                    borderRadius: '50px',
                    zIndex: 1000,
                    pointerEvents: 'none',
                    fontSize: '14px',
                  }}>
                  {draggedFileName}
                </div>
              )}{' '}
            </>
          ) : (
            ''
          )}
        </div>
        <div className={cls.msgPopupBtns} style={{ marginTop: '20px' }}>
          <Button className={clsx(cls.cancelBtn)} onClick={() => Close()}>
            Cancel
          </Button>
          {loading ? (
            <Button className={clsx(cls.submitBtn)}>
              <CircularProgress />
            </Button>
          ) : hasImage === 'preApprovedImage' ? (
            ''
          ) : hasImage === 'approvalFile' ? (
            <Button
              className={clsx(cls.submitBtn)}
              onClick={() => handleUploadApproval()}>
              Confirm
            </Button>
          ) : (
            <Button
              className={clsx(cls.submitBtn)}
              onClick={() => handleUpload()}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
