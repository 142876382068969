import React, { useEffect, useState, useRef } from 'react';
import { usePusher } from '../../App';
import { useHistory } from 'react-router-dom';
import {
  Tooltip,
  IconButton,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import clsx from 'clsx';

import {
  DeleteRounded,
  CreateRounded,
  Share as ShareIcon,
  Message,
} from '@material-ui/icons';
import { fDate, fTimeDate } from '@/actions/Helper';
import styles from './styles';
import {
  disableInventoryContainer,
  enableInventoryContainer,
  getInventoryShareLink,
} from '@/actions';
import { useNotify, Loading } from 'react-admin';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';
import Select from 'react-select';
export default ({
  DATA = [],
  DATAOrders = [],
  DATARequests = [],
  chatTypeParams,
  DelContainer,
  Edit,
  Appointment,
  getInventoryList,
  getInventoryListNotLoading,
  currentPage,
  tableTheadTitle,
  mptyTrackingEnable,
}) => {
  const cls = styles();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('offer');
  const [containersData, setContainersData] = useState(DATA);
  const [ordersList, setOrdersList] = useState(DATAOrders);
  const [requestsList, setRequestsList] = useState(DATARequests);
  const [tableData, setTableData] = useState([]);
  const { channels } = usePusher();
  const [showContainersModal, setShowContainersModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [timerinterval, setTimerInterval] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [enable_empty_tracking, setEnable_empty_tracking] = useState(null);
  const [shareLink, setShareLink] = useState({
    loading: null,
    DATA: null,
  });
  const user_id = useSelector((state) => state?.reducer.user.id);
  const filterOpt = [
    { label: 'Reserved', value: 'reserved' },
    { label: 'Approved', value: 'approved' },
    { label: 'Pre-Approved', value: 'preapproved' },
    { label: 'Disputed', value: 'disputed' },
    { label: 'Rejected', value: 'rejected' },
  ];

  useEffect(() => {
    setContainersData(DATA);
    if (chatTypeParams === 'offer') {
      setActiveTab('offer');
    } else if (chatTypeParams === 'request') {
      setActiveTab('request');
    }
  }, [DATA, chatTypeParams]);

  useEffect(() => {
    setOrdersList(DATAOrders);
    setRequestsList(DATARequests);
  }, [DATAOrders, DATARequests]);

  const notify = useNotify();

  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    // Check if both hours and minutes are 00
    if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  const formatDateToUTC = (dateString) => {
    const date = new Date(dateString);
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).padStart(2, '0')} `;

    return formattedDate;
  };

  const getActivContainers = (inventory, target) => {
    if (inventory) {
      let activeContainers = [];
      let deactiveContainers = [];
      inventory.containers.forEach((container) => {
        if (container.status === 1) {
          activeContainers.push(container);
        } else if (container.status === 0) {
          deactiveContainers.push(container);
        }
      });

      if (deactiveContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getActivContainers, getInventoryList]);
  // console.log('data', DATA);
  let uniqueIndex = 0;

  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);

  const [tableWidth, setTableWidth] = useState(0);
  const [spanWidth, setSpanWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (amirbiosRef.current && amirbios1Ref.current) {
        const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;

        if (amirbiosTop <= 69) {
          amirbios2Ref.current.classList.add(cls.fix);
          tableRef.current.classList.add(cls.tableTopMargin);
        } else {
          amirbios2Ref.current.classList.remove(cls.fix);
          tableRef.current.classList.remove(cls.tableTopMargin);
        }
      }
      syncScroll('amirbios');
    };
    const updateWidth = () => {
      if (tableRef.current && amirbios1Ref.current) {
        const tableCurrentWidth =
          tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableCurrentWidth);
      }
    };

    const updateHeadWidth = () => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        const tableeCurrentWidth =
          amirbiosRef.current.getBoundingClientRect().width;
        setSpanWidth(tableeCurrentWidth);
      }
    };

    const syncScroll = (source) => {
      if (amirbiosRef.current || amirbios2Ref.current) {
        if (source === 'amirbios') {
          // console.log('sss');

          amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
        } else if (source === 'amirbios2') {
          // console.log('www');

          amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
        }
      }
    };

    updateWidth();
    updateHeadWidth();

    const handleScroll1 = () => syncScroll('amirbios');
    const handleScroll2 = () => syncScroll('amirbios2');

    if (amirbiosRef.current) {
      amirbiosRef.current.addEventListener('scroll', handleScroll1);
    }

    if (amirbios1Ref.current) {
      amirbios2Ref.current.addEventListener('scroll', handleScroll2);
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWidth);
    window.addEventListener('resize', updateHeadWidth);
    window.addEventListener('scroll', updateWidth);
    window.addEventListener('scroll', updateHeadWidth);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('resize', updateHeadWidth);
      window.removeEventListener('scroll', updateWidth);
      window.removeEventListener('scroll', updateHeadWidth);

      if (amirbiosRef.current) {
        amirbiosRef.current.removeEventListener('scroll', handleScroll1);
      }

      if (amirbios2Ref.current) {
        amirbios2Ref.current.removeEventListener('scroll', handleScroll2);
      }

      // if (amirbios2Ref.current) {
      //   amirbios2Ref.current.removeEventListener('scroll', () =>
      //     syncScroll('amirbios2')
      //   );
      // }
    };
  }, [DATA, tableTheadTitle, containersData, amirbiosRef, amirbios2Ref]);

  useEffect(() => {
    if (amirbios2Ref.current) {
      amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
    }
  }, [spanWidth]);

  useEffect(() => {
    if (amirbios1Ref.current) {
      amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
    }
  }, [tableWidth]);

  useEffect(() => {
    if (activeTab === 'request') {
      setTableData(requestsList);
    } else if (activeTab === 'offer') {
      setTableData(ordersList);
    }
  }, [activeTab, requestsList, ordersList]);

  console.log('tableData', tableData);

  return (
    <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
      <div className={cls.tabLinksDiv}>
        <div className={cls.tabLinks}>
          <span
            onClick={(e) => setActiveTab('offer')}
            className={
              activeTab === 'offer' ? cls.tabLinksBtnActive : cls.tabLinksBtn
            }>
            Offers
          </span>
          <span
            onClick={(e) => setActiveTab('request')}
            className={
              activeTab === 'request' ? cls.tabLinksBtnActive : cls.tabLinksBtn
            }>
            Requests
          </span>
        </div>
        <div className={cls.tableFilter}>
          <ReactSVG src={Icons.filterNew} className={cls.filterIcon} />
          <Select
            placeholder="Select"
            className={clsx(cls.select)}
            isClearable
            // value={form?.pickup_way}
            // onChange={(pickupWay) => {
            //   updateFormData(index, 'pickup_way', pickupWay.value);
            //   updateFormData(
            //     index,
            //     'container_id',
            //     item.selectedContainer ? item.selectedContainer?.id : ''
            //   );
            // }}
            options={filterOpt}
          />
        </div>
      </div>
      {tableData.length === 0 ? (
        <span className={cls.emptyTableHandler}>
          <ReactSVG src={Icons.emptyHandle} className={cls.emptyTableIcon} />
          There are no orders.
        </span>
      ) : tableData.length > 0 ? (
        <table ref={tableRef} id="table" className={cls.list}>
          {/* {console.log(ordersList)} */}
          <thead ref={amirbios2Ref} id="amirbios1">
            <tr ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
              <th style={{ flex: 0.3 }}>#</th>
              <th style={{ flex: 1 }}>Name</th>
              <th style={{ flex: 0.5 }}>Date & Hour</th>
              <th style={{ flex: 0.5 }}>Size</th>
              <th style={{ flex: 0.6 }}>SSL</th>
              <th style={{ flex: 0.4 }}>Number</th>
              <th style={{ flex: 0.4 }}>Chassis</th>
              <th style={{ flex: 0.5 }}>Flip Service</th>
              <th style={{ flex: 3 }}>Message</th>
              <th style={{ flex: 0.8 }}>Status</th>
              {/* <th style={{ flex: 0.6 }}>Action</th> */}
            </tr>
          </thead>
          <tbody className={cls.tableBody}>
            {tableData.map((item, idx) => (
              <tr
                onClick={() =>
                  history.push(`/inbox/chat/${item.id}-${item.mode}-${user_id}`)
                }
                className={
                  item.last_message_object.is_seen === 0
                    ? cls.tableRowUnSeen
                    : cls.tableRow
                }
                key={`${uniqueIndex}new`}>
                <td style={{ flex: 0.3 }}>{idx + 1}</td>
                <td style={{ flex: 1 }}>
                  {item.staus === 1
                    ? 'Unknown'
                    : activeTab === 'request' && !item.inventory?.user
                    ? 'Unknown'
                    : item.inventory?.user?.company}
                  {activeTab === 'order' &&
                  (item.staus === 1 || !item?.inventory_with_out_user?.user)
                    ? 'Unknown'
                    : item?.user?.company}
                </td>
                <td style={{ flex: 0.5 }}>
                  {formatDateTimeToUTC(item.created_at)}
                </td>
                {/* <td style={{ flex: 0.6 }}>{item.mode}</td> */}
                <td style={{ flex: 0.5 }}>
                  {activeTab === 'request'
                    ? item.inventory?.cabin?.specifications?.size +
                      '-' +
                      item.inventory?.cabin?.specifications?.name +
                      '-' +
                      item.inventory?.cabin?.specifications?.model
                    : item.inventory_with_out_user?.cabin?.specifications
                        ?.size +
                      '-' +
                      item.inventory_with_out_user?.cabin?.specifications
                        ?.name +
                      '-' +
                      item.inventory_with_out_user?.cabin?.specifications
                        ?.model}
                </td>
                <td style={{ flex: 0.6 }}>
                  {activeTab === 'request'
                    ? item.inventory?.cabin?.company?.name
                    : item.inventory_with_out_user?.cabin?.company.name}
                </td>
                <td style={{ flex: 0.4 }}>{item.requested_count}</td>
                <td style={{ flex: 0.4 }}>{item.chase_type}</td>
                <td style={{ flex: 0.5 }}>
                  {activeTab === 'request'
                    ? item.inventory?.flip_available === '1'
                      ? 'Yes'
                      : 'No'
                    : item.inventory_with_out_user?.flip_available === '1'
                    ? 'Yes'
                    : 'No'}
                </td>
                {item.last_message_object.is_seen === 0 ? (
                  <Tooltip arrow title={item?.last_message} placement="top">
                    <td className={cls.lastMsgNuSeenTd} style={{ flex: 3 }}>
                      <span className={cls.dotSpan}>● </span>
                      {item?.last_message}
                    </td>
                  </Tooltip>
                ) : (
                  <td className={cls.lastMsgTd} style={{ flex: 3 }}>
                    {item?.last_message}
                  </td>
                )}
                <td style={{ flex: 0.8 }}>
                  <span
                    className={
                      item.status_string === 'Pre-Approved'
                        ? cls.statusSpanPreApproved
                        : item.status_string === 'Approved'
                        ? cls.statusSpanApproved
                        : item.status_string === 'Rejected'
                        ? cls.statusSpanRejected
                        : item.status_string === 'Disputed'
                        ? cls.statusSpanDisputed
                        : item.status_string === 'Reserved'
                        ? cls.statusSpanReserved
                        : cls.statusSpan
                    }>
                    {item.status_string}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};
