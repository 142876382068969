import React from 'react';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import closeIcon from '../../../assets/img/closeIcon.svg';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { useOutsideAlerter } from '@/hocs';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import { TextareaAutosize } from '@mui/material';
import Select from 'react-select';
import { adminUploadHtmlToScrape } from '@/actions';
import { Loading, useNotify } from 'react-admin';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';

export default ({
  Close,
  DATA,
  preApproverowIDs,
  requestedCount,
  setSelectedContainerItem,
  preApprovedContainers,
  preApprovedIndexContainerId,
}) => {
  const cls = styles();
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });
  const setForm = (it, val) => setFormData({ ...form, [it]: val });
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);

  console.log('selectedRows', selectedRows);
  console.log('DATA', DATA);
  React.useEffect(() => {
    if (preApproverowIDs && DATA?.inventory?.containers) {
      const preSelectedRows = DATA.inventory.containers
        .filter((item) => preApproverowIDs.includes(item.id))
        .map((item) => ({
          item,
          preApproverowID: item.id,
        }));

      setSelectedRows(preSelectedRows);
    }
  }, [preApproverowIDs, DATA]);

  const [form, setFormData] = React.useState({
    slug: '',
    content: '',
  });
  const formatDateTimeToUTC = (dateString) => {
    console.log('dateString', dateString);

    if (dateString) {
      const date = new Date(dateString);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const formattedDate =
        `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
        `${String(date.getUTCDate()).padStart(2, '0')}-` +
        `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

      // Check if both hours and minutes are 00
      if (hours === 0 && minutes === 0) {
        return formattedDate; // Return only the date
      } else {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
          2,
          '0'
        )} ${formattedDate}`; // Return time and date
      }
    } else {
      return '-';
    }
  };
  const formatPrice = (price) => {
    if (price) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(price);
    } else return '-';
  };

  const handleSelect = (item, preApproverowID) => {
    setSelectedRows((prevSelectedRows) => {
      const isAlreadySelected = prevSelectedRows.some(
        (row) => row.preApproverowID === preApproverowID
      );

      if (isAlreadySelected) {
        return prevSelectedRows.filter(
          (row) => row.preApproverowID !== preApproverowID
        );
      } else {
        if (prevSelectedRows.length >= requestedCount) {
          notify(
            `You can select a maximum of ${requestedCount} containers only.`,
            'warning'
          );
          return prevSelectedRows;
        } else {
          return [...prevSelectedRows, { item, preApproverowID }];
        }
      }
    });
  };

  const handleCancle = (item, preApproverowID) => {
    Close();
  };
  const handleSave = () => {
    setSelectedContainerItem(selectedRows);
    // console.log(
    //   'Selected rows sent to setSelectedContainerItem:',
    //   selectedRows
    // );
    Close();
  };
  React.useEffect(() => {
    // console.log('Selected rows updated:', selectedRows);
  }, [selectedRows]);

  console.log('DATA', DATA);

  return (
    <div
      id="app_modal"
      onClick={(e) => e.target.id === 'app_modal'}
      className={cls.popup}>
      <div className={cls.card}>
        {loading ? (
          <Loading className={cls.loading} />
        ) : (
          <>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded className={cls.closeP} onClick={Close} />
            </Tooltip>

            <div ref={wrapperRef} className={cls.popupCard}>
              <span className={cls.tableTopActions}>
                <ReactSVG
                  src={Icons.containerTruck}
                  className={cls.dicsounticon}
                />
                Lorem Ipsum
              </span>
              <span>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </span>
              <table id="table" className={cls.listPopup}>
                <thead id="amirbios1">
                  <tr id="amirbios2" className={cls.theadPreApprove}>
                    {preApprovedContainers === 'preApprovedContainers' ? (
                      ''
                    ) : (
                      <th style={{ flex: 0.4 }}>Action</th>
                    )}
                    <th style={{ flex: 0.2 }}>#</th>
                    <th style={{ flex: 1 }}>Size</th>
                    <th style={{ flex: 1 }}>SSL</th>
                    <th style={{ flex: 1 }}>Container</th>
                    <th style={{ flex: 1 }}>Chassis</th>
                    <th style={{ flex: 1 }}>Gated Out</th>
                    <th style={{ flex: 1 }}>Gated In</th>
                    <th style={{ flex: 1 }}>Empty Location</th>
                    <th style={{ flex: 1 }}>Return Apt</th>
                    <th style={{ flex: 1 }}>Return Location</th>
                    <th style={{ flex: 1 }}>Dual Terminal</th>
                    <th style={{ flex: 1 }}>LFD</th>
                    <th style={{ flex: 1 }}>Est.PerDiem</th>
                    <th style={{ flex: 1 }}>Chassis Type</th>
                    <th style={{ flex: 1 }}>Last Update</th>
                    {/* <th style={{ flex: 1 }}></th> */}
                  </tr>
                </thead>
                <tbody className={cls.tableBodyPopup}>
                  {(preApprovedContainers === 'preApprovedContainers'
                    ? DATA.preApprovedContainersList
                    : DATA.inventory.containers
                  )?.map((item, idx) => (
                    <tr
                      onClick={() =>
                        preApprovedContainers === 'preApprovedContainers'
                          ? ''
                          : handleSelect(item, item.id)
                      }
                      className={
                        preApprovedContainers === 'preApprovedContainers' &&
                        DATA.preapprovedcontainer?.container.id ===
                          item.container?.id
                          ? cls.tableRowSelected
                          : preApprovedContainers === 'preApprovedContainers'
                          ? cls.tableRowPreApprove
                          : DATA.selectedContainerID === item?.id
                          ? cls.tableRowSelected
                          : cls.tableRowSelectable
                      }>
                      {preApprovedContainers === 'preApprovedContainers' ? (
                        ''
                      ) : (
                        <td style={{ flex: 0.4 }}>
                          <Checkbox
                            checked={selectedRows.some(
                              (row) => row.preApproverowID === item.id
                            )}
                            // onChange={() => handleSelect(item, DATA.id)}
                            className={cls.checkbox}
                          />
                        </td>
                      )}
                      <td style={{ flex: 0.2 }}>{idx + 1}</td>
                      <td style={{ flex: 1 }}>{DATA.size}</td>
                      <td style={{ flex: 1 }}>{DATA.ssl}</td>
                      <td style={{ flex: 1 }}>
                        {item.name || item.container.name}
                      </td>
                      <td style={{ flex: 1 }}>
                        {item.chases || item.container.chases}
                      </td>
                      <td style={{ flex: 1 }}>
                        {item.gated_out === null
                          ? '-'
                          : item.gated_out
                          ? formatDateTimeToUTC(item.gated_out)
                          : formatDateTimeToUTC(item.container.gated_out)}
                      </td>
                      <td style={{ flex: 1 }}>
                        {item.gated_in === null
                          ? '-'
                          : item.gated_in
                          ? formatDateTimeToUTC(item.gated_in)
                          : item.container?.gated_in
                          ? formatDateTimeToUTC(item.container.gated_in)
                          : '-'}
                      </td>
                      <td style={{ flex: 1 }}>
                        {item.container?.pickup_location || DATA.address?.city}
                      </td>
                      <td style={{ flex: 1 }}>
                        {item.return_appointment === null
                          ? '-'
                          : item.return_appointment
                          ? formatDateTimeToUTC(item.return_appointment)
                          : formatDateTimeToUTC(
                              item.container.return_appointment
                            )}
                      </td>
                      <td style={{ flex: 1 }}>-</td>
                      <td style={{ flex: 1 }}>-</td>
                      <td style={{ flex: 1 }}>
                        {item.last_free_day === null
                          ? '-'
                          : item.last_free_day
                          ? formatDateTimeToUTC(item.last_free_day)
                          : formatDateTimeToUTC(item.container.last_free_day)}
                      </td>
                      <td style={{ flex: 1 }}>
                        {item.est_per_diem === null
                          ? '-'
                          : item.est_per_diem
                          ? formatPrice(item.est_per_diem)
                          : formatPrice(item.container?.est_per_diem)}
                      </td>
                      <td style={{ flex: 1 }}>
                        {item.own_chases === 1
                          ? 'Own'
                          : 'Pool' || item.container.own_chases === 1
                          ? 'Own'
                          : 'Pool'}
                      </td>
                      <td style={{ flex: 1 }}>
                        {item.updated_at
                          ? formatDateTimeToUTC(item.updated_at)
                          : formatDateTimeToUTC(item.container.updated_at)}
                      </td>
                      {/* <td style={{ flex: 1 }}>SELECT</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>

              <span className={cls.msgPopupBtns}>
                <Button
                  className={clsx(cls.cancelBtn)}
                  onClick={(e) => {
                    handleCancle();
                  }}>
                  Cancel
                </Button>
                {preApprovedContainers === 'preApprovedContainers' ? (
                  ''
                ) : (
                  <Button
                    className={clsx(cls.submitBtn)}
                    onClick={() => {
                      handleSave();
                    }}>
                    Submit {selectedRows.length} selected
                  </Button>
                )}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
