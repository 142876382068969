import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    // padding: '10px 0',
    boxSizing: 'border-box',
    width: 360,
    height: '85vh',
    // boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    // backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // borderRadius: 10,
    position: 'fixed',
    right: '9vw',
    zIndex: 999999999,
    top: 80,
    '@media (max-width:1000px)': {
      bottom: '90px',
      right: 80,
      width: '360px',
      height: '620px',
      top: 'unset',
    },
    '@media (max-width:500px)': {
      bottom: '0',
      right: '0',
      top: '72px',
      left: '0',
      width: '100%',
      height: 'auto',
      zIndex: '999999999999',
    },
  },
  chatCard: {},
  container: {
    position: 'fixed',
    left: 'auto',
    right: 0,
    top: 80,
    zIndex: '3',
  },
  drawerPaper: {
    position: 'absolute',
    height: '100vh',
    overflowX: 'hidden',
    width: '95px',
    backgroundColor: 'drawerPaper',
    zIndex: 10,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    boxShadow: '-2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    padding: '40px 0px',
    transition: 'all .2s ease-in',
  },
  miniDrawer: {
    padding: '20px 3px',
  },
  title: {
    fontFamily: 'Averta',
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1,
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: 25,
    cursor: 'default',
  },
  item: {
    position: 'relative',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    // borderRadius: 10,
    width: 95,
    margin: '0 auto',
    fontFamily: 'Averta',
    fontSize: 15,
    lineHeight: 1,
    color: '#757575',
    gap: '15px',
    padding: '5px 20px',
    '&:hover': {
      boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  close: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0',
    margin: '0 auto',
  },
  icon: {
    justifyContent: 'center',
    // marginBottom: 10,
  },
  redDot: {
    position: 'absolute',
    top: 5,
    right: 22,
    width: 12,
    height: 12,
    borderRadius: 10,
    backgroundColor: '#e4464b',
    display: ({ notif }) =>
      notif.order || notif.request || notif.archive || notif.support
        ? 'block'
        : 'none',
  },
  requestDot: {
    display: ({ notif }) => (notif.request ? 'block' : 'none'),
  },
  orderDot: {
    display: ({ notif }) => (notif.order ? 'block' : 'none'),
  },
  archiveDot: {
    display: ({ notif }) => (notif.archive ? 'block' : 'none'),
  },
  supportDot: {
    display: ({ notif }) => (notif.support ? 'block' : 'none'),
  },
  order: {
    animation: ({ notif }) => (notif.order ? 'heartBeat 1s' : 'zoomIn 1s'),
  },
  request: {
    animation: ({ notif }) => (notif.request ? 'heartBeat 1s' : 'zoomIn 1s'),
  },
  archive: {
    animation: ({ notif }) => (notif.archive ? 'heartBeat 1s' : 'zoomIn 1s'),
  },
  support: {
    animation: ({ notif }) => (notif.support ? 'heartBeat 1s' : 'zoomIn 1s'),
  },
  mobileChatBtn: {
    position: 'fixed',
    right: '16px',
    bottom: '16px',
    color: '#ffffff',
    background: '#000000',
    borderRadius: '50%',
    width: '64px',
    height: '64px',
    zIndex: '999',
    boxShadow: '2px 2px 10px 0 rgb(0 0 0 / 20%)',
    transition: 'all ease 0.3s',
    '&:hover': {
      background: '#323232',
      boxShadow: '0px 0px 20px 0 rgb(255 152 0 / 62%)',
      scale: '1.1',
      transition: 'all ease 0.3s',
    },
  },
  mobileChatBarItems: {
    background: '#f0f0f0',
    position: 'fixed',
    right: '5px',
    bottom: '90px',
    borderRadius: '10px',
    padding: '5px',
    zIndex: '999',
  },
  bellIcon: {
    position: 'relative',
    // margin: '0 15px',
    '& img': {
      height: 27,
      '@media (max-width:1160px)': {
        height: '22px',
      },
    },
    animation: ({ notif }) =>
      notif.chat || notif.order || notif.request ? 'heartBeat 1s' : '',
    '@media (max-width:1160px)': {
      margin: '10px',
    },
    '@media (max-width:800px)': {
      margin: '5px',
    },
  },
  paper: {
    top: '75px !important',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    borderRadius: 10,
    overflow: 'visible',
    '&::before': {
      position: 'absolute',
      right: 16,
      top: -13,
      width: 25,
      height: 25,
      backgroundColor: '#fff',
      content: '""',
      transform: 'rotate(-45deg)',
      borderRadius: '0 5px',
      zIndex: 0,
    },
  },
  notifIcon: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    fontSize: '14px',
    color: '#ffffff',
  },
}));
