import {
  getAdminNewChat,
  RejectOrder,
  RejectOrderExport,
  SaveData,
  seenChat,
  sendApproveData,
  sendPreApproveData,
} from '@/actions';
import { useHistory } from 'react-router-dom';
import AdminChatFooter from '@/components/Chat/AdminChatFooter';
import AdminChatHeader from '@/components/Chat/AdminChatHeader';
import React, { useEffect } from 'react';
import styles from './styles';
import { useSelector } from 'react-redux';
import { pushClient, _get, fTimeDate, locationOpt } from '@/actions/Helper';
import Chat from '../../../components/Chat/Chat';
import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Input from '../../../components/Chat/Input';
import { Avatar, Grid, TextField } from '@mui/material';
import Select from 'react-select';
import Containers from './Containers';
import TimePicker from 'rc-time-picker';
import AsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { values } from 'lodash';
import ImageUpload from './ImageUpload';
import { Loading, useNotify } from 'react-admin';
import { Icons } from '@/assets';
import { ReactSVG } from 'react-svg';
import TimePickerFromTo from '@/components/Chat/TimePickerFromTo';
import ErrorPopup from './ErrorPopup';

const parseData = (data, user) =>
  data.map((i) => ({
    message: i.message,
    time: i.humans_time,
    me: i.user_id === user.id,
    name: _get(i, 'sender.name', ''),
    avatar: _get(i, 'sender.image', ''),
  }));

export default ({ chatType, orderStatus, orderDetail, user_id }) => {
  const [state, setState] = React.useState({
    headerData: {},
    user: {},
    DATA: [],
    otherPart: {},
    loading: true,
    popUp: false,
  });
  const [form, setFormData] = React.useState([
    {
      container_id: null,
      pickup_way: null,
      pickup_location: null,
      pickup_date_start: null,
      pickup_date_end: null,
      container_conditions: '',
    },
  ]);
  const [formApprove, setFormApproveData] = React.useState([
    {
      container_id: null,
      approve_status: null,
    },
  ]);

  const history = useHistory();
  const [openRowId, setOpenRowId] = React.useState(null); // Manage which row's popup is open
  const user = useSelector((state) => state.reducer.user);
  const [pusher, setPusher] = React.useState(null);
  const [imageUploads, setImageUploads] = React.useState([]);
  const [containersItemsState, setContainersItemsState] = React.useState([]);
  const [requestedCount, setRequestedCount] = React.useState();
  const [accepted_count, setAccepted_count] = React.useState();
  const [count_all, setcount_all] = React.useState();
  const [fromTime, setFromTime] = React.useState({ hour: 10, amPm: 'AM' });
  const [toTime, setToTime] = React.useState({ hour: 11, amPm: 'AM' });
  const [times, setTimes] = React.useState([]);
  const [selectedContainerItem, setSelectedContainerItem] = React.useState([]);
  const [preApprovedContainersList, setPreApprovedContainersList] =
    React.useState([]);
  const [approvedContainersList, setapprovedContainersList] = React.useState(
    []
  );
  const [preApproverowIDs, setPreApproverowIDs] = React.useState([]);
  const [containersPopup, setContainersPopup] = React.useState({
    DATA: null,
    loading: true,
    popUp: false,
  });
  const [imageUploadPopup, setImageUploadPopup] = React.useState({
    DATA: null,
    loading: true,
    popUp: false,
  });
  const [errorPopup, setErrorPopup] = React.useState({
    DATA: null,
    loading: true,
    popUp: false,
  });
  const pickupDropoffOpt = [
    { label: 'Pickup', value: 'pickup' },
    { label: 'Dropoff', value: 'dropoff' },
  ];
  const conditionOpts = [
    { label: 'Clean', value: 'clean' },
    { label: 'Damaged', value: 'Damaged' },
    { label: 'Repair Needed', value: 'Repair Needed' },
    { label: 'Not Checked', value: 'Not Checked' },
  ];

  const [value, setValue] = React.useState(requestedCount);

  // const setForm = (key, value) => {
  //   if (key === 'containers') {
  //     setFormData((prevForm) => ({
  //       ...prevForm,
  //       containers: [...prevForm.containers, value],
  //     }));
  //   } else {
  //     setFormData((prevForm) => ({
  //       ...prevForm,
  //       [key]: value,
  //     }));
  //   }
  // };
  const increment = () => {
    if (value < requestedCount) {
      setValue(value + 1);
      setcount_all(value + 1);
    }
  };

  const decrement = () => {
    if (value > 1) {
      setValue(value - 1);
      setcount_all(value - 1);
    }
  };

  React.useEffect(() => {
    setValue(requestedCount);
  }, [requestedCount]);

  const updateFormData = (index, key, value) => {
    setFormData((prev) => {
      const updatedData = [...prev];

      if (!updatedData[index]) {
        updatedData[index] = {};
      }

      const keys = key.split('.');
      let current = updatedData[index];

      keys.forEach((k, i) => {
        if (i === keys.length - 1) {
          current[k] = value;
        } else {
          if (!current[k]) {
            current[k] = {};
          }
          current = current[k];
        }
      });

      return updatedData;
    });
  };
  const updateFormApproveData = (index, key, value) => {
    setFormApproveData((prev) => {
      const updatedData = [...prev];

      if (!updatedData[index]) {
        updatedData[index] = {};
      }

      const keys = key.split('.');
      let current = updatedData[index];

      keys.forEach((k, i) => {
        if (i === keys.length - 1) {
          current[k] = value;
        } else {
          if (!current[k]) {
            current[k] = {};
          }
          current = current[k];
        }
      });

      return updatedData;
    });
  };

  const updateFormApproveDates = (index, key, value) => {
    const currentDate = new Date();

    const fromDateTime = new Date(currentDate);
    fromDateTime.setHours(7, 0, 0, 0);

    const toDateTime = new Date(currentDate);
    toDateTime.setHours(16, 0, 0, 0);

    updateFormData(index, 'pickup_date_start', fromDateTime);
    updateFormData(index, 'pickup_date_end', toDateTime);
  };

  console.log('formApprove', formApprove);
  console.log('form', form);

  const RejectEmpty = (id) => {
    RejectOrder(id)
      .then(({ data, msg }) => {
        history.push(`/inbox`);
        // let DATA = ParseOrder(data);
        // setData(DATA);
        // setFilter({ open: false, DATA });
        notify(msg);
      })
      .catch((err) => notify(err, 'warning'));
  };

  function extractTime(input) {
    if (!input) {
      return null;
      // throw new Error('Invalid input');
    }

    const date = typeof input === 'string' ? new Date(input) : input;

    if (isNaN(date)) {
      throw new Error('Invalid date format');
    }

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  React.useEffect(() => {
    seenChat(orderDetail?.headerData.id, orderDetail?.conversation.id);

    for (const msg of orderDetail?.conversation.messages || []) {
      if (msg.message_type === '3' && msg.message_type === '2') {
      }
    }
    for (const msg of orderDetail?.conversation.messages || []) {
      if (msg.message_type === '1') {
        setPreApprovedContainersList(
          orderDetail?.headerData.pre_approved_request_data
        );
        setapprovedContainersList(
          orderDetail?.headerData.approved_request_data
        );
      }
    }
  }, [orderDetail]);

  const RejectExport = (id) => {
    RejectOrderExport(id)
      .then(({ data, msg }) => {
        // let DATA = ParseOrder(data);
        // setData(DATA);
        // setFilter({ open: false, DATA });
        notify(msg);
      })
      .catch((err) => notify(err, 'warning'));
  };
  React.useEffect(() => {
    setRequestedCount(orderDetail?.headerData.requested_count);
    setAccepted_count(orderDetail?.headerData.accepted_count);
    setcount_all(
      orderDetail?.headerData.accepted_count
        ? orderDetail?.headerData.accepted_count
        : orderDetail?.headerData.requested_count
    );
  }, [orderDetail]);

  React.useEffect(() => {
    const containersItems = Array.from({ length: count_all }, (_, index) => {
      // اطمینان از اینکه preApprovedContainersList[index] یک آرایه است
      const preApprovedList = Array.isArray(preApprovedContainersList)
        ? preApprovedContainersList[index]
        : [];

      // گرفتن کانتینرهای preapproved از inventory
      // const preapprovedcontainer =
      //   orderDetail?.headerData.pre_approved_request_data?.filter(
      //     (container) =>
      //       Number(preApprovedContainersList[index]?.container_id) ===
      //       container?.id
      //   );

      return {
        id: index,
        orderStatus: orderDetail?.headerData.staus,
        size:
          orderDetail?.headerData.cabin.specifications.size +
          '-' +
          orderDetail?.headerData.cabin.specifications.name +
          '-' +
          orderDetail?.headerData.cabin.specifications.model,
        ssl: orderDetail?.headerData.cabin.company?.name,
        location: orderDetail?.headerData.inventory?.address?.city,
        inventory: orderDetail?.headerData.inventory,
        preApprovedContainersList: preApprovedContainersList,
        approvedContainersList: approvedContainersList,
        approvedcontainer: approvedContainersList[index],
        preapprovedcontainer: preApprovedContainersList[index],
      };
    });

    setContainersItemsState(containersItems);
  }, [orderDetail, count_all, preApprovedContainersList]);

  console.log('ContainersItemsState', containersItemsState);

  const addItemToOldList = (newItems, oldList) => {
    console.log('newItems', newItems);

    if (!newItems || newItems.length === 0) {
      return oldList.map((item) => ({
        ...item,
        selectedContainerID: null,
        selectedContainer: null,
      }));
    }

    const updatedList = [...oldList];

    // console.log('selectedContainerItem', selectedContainerItem);
    // console.log('containersItemsState', containersItemsState);

    // updateFormData(
    //   containersItemsState.id,
    //   'container_id',
    //   selectedContainerItem ? selectedContainerItem?.preApproverowID : ''
    // );
    // updateFormApproveDates(containersItemsState.id);

    newItems.forEach((newItem, index) => {
      updateFormApproveDates(index);
      updateFormData(index, 'container_id', newItem.preApproverowID);
      if (index < updatedList.length) {
        updatedList[index] = {
          ...updatedList[index],
          id: newItem.preApproverowID,
          selectedContainerID: newItem.item?.id || null,
          selectedContainer: newItem.item || null,
        };
      } else {
        updatedList[index] = {
          id: newItem.preApproverowID,
          selectedContainerID: newItem.item?.id || null,
          selectedContainer: newItem.item || null,
        };
      }
    });

    for (let i = newItems.length; i < updatedList.length; i++) {
      updatedList[i] = {
        ...updatedList[i],
        selectedContainerID: null,
        selectedContainer: null,
      };
    }

    return updatedList;
  };

  const extractPreApproverowIDs = (newItems) => {
    return newItems.map((item) => item.preApproverowID);
  };

  const updateContainersList = () => {
    const updatedList = addItemToOldList(
      selectedContainerItem || [],
      containersItemsState
    );

    setContainersItemsState(updatedList);

    const preApproverowIDs = extractPreApproverowIDs(
      selectedContainerItem || []
    );
    setPreApproverowIDs(preApproverowIDs);
  };

  React.useEffect(() => {
    updateContainersList();
  }, [selectedContainerItem]);

  const handleContainersSelect = (
    containers,
    preApprovedContainers,
    preApprovedIndexContainerId
  ) => {
    setContainersPopup((prev) => {
      return {
        ...prev,
        popUp: true,
        DATA: containers,
        requestedCount: requestedCount,
        preApprovedContainers: preApprovedContainers,
        preApprovedIndexContainerId: preApprovedIndexContainerId,
      };
    });
  };
  const handleImageUpload = (containers, hasImage, containerID) => {
    setImageUploadPopup((prev) => {
      return {
        ...prev,
        popUp: true,
        DATA: containers,
        hasImage: hasImage,
        orderStatus: orderStatus,
        chatType: chatType,
        containerID,
        orderID: orderDetail?.headerData.id,
      };
    });
  };
  const handleErrorPopup = (containers, hasImage) => {
    setErrorPopup((prev) => {
      return {
        ...prev,
        popUp: true,
        DATA: containers,
        hasImage: hasImage,
        orderStatus: orderStatus,
        chatType: chatType,
        orderID: orderDetail?.headerData.id,
      };
    });
  };
  const cls = styles();

  const listRef = React.useRef();
  const notify = useNotify();

  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    // Check if both hours and minutes are 00
    if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  const validateFormData = (form) => {
    for (let i = 0; i < form.length; i++) {
      const data = form[i];
      for (const key in data) {
        if (data[key] === null || data[key] === undefined || data[key] === '') {
          return `Field "${key}" in row ${i + 1} cannot be empty.`;
        }
        if (typeof data[key] === 'object' && data[key] !== null) {
          for (const nestedKey in data[key]) {
            if (
              data[key][nestedKey] === null ||
              data[key][nestedKey] === undefined ||
              data[key][nestedKey] === ''
            ) {
              return `Field "${nestedKey}" in "${key}" in row ${
                i + 1
              } cannot be empty.`;
            }
          }
        }
      }
    }
    return null;
  };

  const formatDateTime = (date) => {
    const d = new Date(date);

    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const formattedToTime = formatDateTime(toTime);
  const formattedFromTime = formatDateTime(fromTime);

  const formatPickupDate = (pickupDate) => {
    if (!pickupDate || typeof pickupDate !== 'object') return null;

    const { start_time, end_time } = pickupDate;
    // return `${start_time || ''} - ${end_time || ''}`;
    return formatDateTime(start_time);
  };
  const handlePreApproveData = () => {
    const validationError = validateFormData(form);
    if (validationError) {
      notify(validationError, 'warning');
      return;
    }

    const formattedData = form.map((container) => ({
      ...container,
      pickup_date_start: formatDateTime(container.pickup_date_start),
      pickup_date_end: formatDateTime(container.pickup_date_end),
    }));

    const payload = {
      orderID: orderDetail?.headerData.id,
      order_type: orderDetail.headerData.mode,
      containers: formattedData,
    };
    sendPreApproveData(payload)
      .then((res) => {
        notify(res.message, 'success');
      })
      .catch((err) => {
        notify(err.message || 'Error when upload', 'warning');
      });
  };

  const handleApproveData = () => {
    const validationError = validateFormData(formApprove);
    if (validationError) {
      notify(validationError, 'warning');
      return;
    }

    const formattedData = formApprove.map((container) => ({
      ...container,
    }));

    const payload = {
      orderID: orderDetail?.headerData.id,
      containers: formattedData,
    };
    sendApproveData(payload)
      .then((res) => {
        notify(res.message, 'success');
      })
      .catch((err) => {
        notify(err.message || 'Error when upload', 'warning');
      });
  };

  console.log('orderDetail', orderDetail);

  const parseSerializedPHP = (data) => {
    let index = 0;

    const parseValue = () => {
      const type = data[index++];
      index++; // رد کردن کاراکتر ':'

      switch (type) {
        case 's': {
          // String
          const length = parseInt(
            data.slice(index, data.indexOf(':', index)),
            10
          );
          index = data.indexOf(':', index) + 2; // رد کردن ':'
          const value = data.slice(index, index + length);
          index += length + 2; // رد کردن ';"'
          return value;
        }
        case 'i': {
          // Integer
          const value = parseInt(
            data.slice(index, data.indexOf(';', index)),
            10
          );
          index = data.indexOf(';', index) + 1;
          return value;
        }
        case 'b': {
          // Boolean
          const value = data[index] === '1';
          index += 2; // رد کردن ';'
          return value;
        }
        case 'N': {
          // Null
          index++; // رد کردن ';'
          return null;
        }
        case 'a': {
          // Array
          const length = parseInt(
            data.slice(index, data.indexOf(':', index)),
            10
          );
          index = data.indexOf(':', index) + 2; // رد کردن ':'
          const obj = {};
          for (let i = 0; i < length; i++) {
            const key = parseValue();
            const value = parseValue();
            obj[key] = value;
          }
          index++; // رد کردن '}'
          return obj;
        }
        default:
        // throw new Error(`Unsupported type: ${type}`);
      }
    };

    return parseValue();
  };

  React.useEffect(() => () => pusher && pusher.disconnect(), [pusher]);

  React.useEffect(() => {
    const { current } = listRef;
    if (current) current.scrollTop = current.scrollHeight;
    // let scroll_to_bottom = document.getElementById('chatslist');
    if (current) {
      current.scrollTop = current.scrollHeight;
    }
  }, [state.DATA]);

  return (
    <div className={cls.messagesContainer}>
      {containersPopup.popUp && (
        <Containers
          DATA={containersPopup.DATA}
          preApprovedContainers={containersPopup.preApprovedContainers}
          preApprovedIndexContainerId={
            containersPopup.preApprovedIndexContainerId
          }
          requestedCount={requestedCount}
          preApproverowIDs={preApproverowIDs}
          setSelectedContainerItem={setSelectedContainerItem}
          Close={() =>
            setContainersPopup((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}
      {imageUploadPopup.popUp && (
        <ImageUpload
          DATA={imageUploadPopup.DATA}
          hasImage={imageUploadPopup.hasImage}
          orderStatus={imageUploadPopup.orderStatus}
          chatType={imageUploadPopup.chatType}
          orderID={imageUploadPopup.orderID}
          containerID={imageUploadPopup.containerID}
          setSelectedContainerItem={setSelectedContainerItem}
          Close={() =>
            setImageUploadPopup((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}
      {errorPopup.popUp && (
        <ErrorPopup
          orderID={errorPopup.orderID}
          RejectEmpty={RejectEmpty}
          Close={() =>
            setErrorPopup((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}

      {!orderDetail ? (
        <div className={cls.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div>
            <div ref={listRef} id="chatslistLeft" className={cls.chatBox}>
              <span className={cls.topSection}>
                {chatType == 'request' ? (
                  <div className={cls.infoChatDiv}>
                    <ReactSVG
                      src={Icons.infoCircle}
                      className={cls.infoCircleIcon}
                    />
                    <span className={cls.infoChat}>
                      <span>Your request has been sent.</span>
                      <span>
                        Your reservation will be confirmed once they
                        pre-approved it.
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className={cls.infoChatDiv}>
                    <ReactSVG
                      src={Icons.clock}
                      className={cls.infoCircleIcon}
                    />
                    {orderDetail.headerData.staus === 1 ? (
                      <span className={cls.infoChat}>
                        <span>
                          Your empty containers are still available to others
                          until you pre-approve the requests.
                        </span>
                        <span>
                          Respond within 2:00:00 to maintain respond rate.
                        </span>
                      </span>
                    ) : orderDetail.headerData.staus === 0 ? (
                      <span className={cls.infoChat}>
                        <span>Your request has been canceled.</span>
                      </span>
                    ) : orderDetail.headerData.staus === 2 ? (
                      <span className={cls.infoChat}>
                        <span>
                          The Street Turn has been successfully completed.
                        </span>
                      </span>
                    ) : orderDetail.headerData.staus === 4 ? (
                      <span className={cls.infoChat}>
                        <span>Your request has been Pre-Approved.</span>
                      </span>
                    ) : (
                      <span className={cls.infoChat}>
                        <span>waiting for recive response.</span>
                      </span>
                    )}
                  </div>
                )}
                <span className={cls.msgPopupBtns}>
                  {orderStatus.value === 3 ? (
                    <Button
                      className={clsx(cls.desclineBtn)}
                      onClick={(e) => {
                        // handleErrorPopup(orderDetail?.headerData.id);
                        // RejectEmpty(orderDetail?.headerData.id);
                      }}>
                      Despute
                    </Button>
                  ) : orderStatus.value !== 0 ? (
                    <Button
                      className={clsx(cls.desclineBtn)}
                      onClick={(e) => {
                        handleErrorPopup(orderDetail?.headerData.id);
                        // RejectEmpty(orderDetail?.headerData.id);
                      }}>
                      Decline & Cancel
                    </Button>
                  ) : (
                    ''
                  )}
                </span>
              </span>
              <div className={cls.rowSection}>
                <span className={cls.sectionSpacer}>
                  <ReactSVG src={Icons.newTick} className={cls.tickIconNew} />
                  <span className={cls.sectionSpacerTitle}>
                    Reservation
                    {orderDetail?.conversation?.user_one === Number(user_id)
                      ? ' Recived '
                      : ' Sent '}
                    at{' '}
                    {formatDateTimeToUTC(orderDetail?.headerData?.created_at)}
                  </span>
                </span>
                <span className={cls.sectionDetail}>
                  <table id="table" className={cls.list}>
                    <thead id="amirbios1">
                      <tr id="amirbios2" className={cls.thead}>
                        <th style={{ flex: 0.3 }}>Name</th>
                        <th style={{ flex: 0.3 }}>Size</th>
                        <th style={{ flex: 0.4 }}>SSL</th>
                        <th style={{ flex: 0.3 }}>Number</th>
                        <th style={{ flex: 0.5 }}>City</th>
                        <th style={{ flex: 0.3 }}>Chassis</th>
                        <th style={{ flex: 0.3 }}>Flip Service</th>
                        <th style={{ flex: 0.3 }}>Flip Hours</th>
                        <th style={{ flex: 0.3 }}>Request</th>
                        <th style={{ flex: 0.6 }}>Address</th>
                      </tr>
                    </thead>
                    <tbody className={cls.tbody}>
                      <tr className={cls.tableRow}>
                        <td style={{ flex: 0.3 }}>
                          {orderDetail?.headerData.staus !== 1
                            ? orderDetail?.conversation?.user_one ===
                              Number(user_id)
                              ? orderDetail?.conversation?.usertwo.name +
                                ' ' +
                                orderDetail?.conversation?.usertwo.last_name
                              : orderDetail?.conversation?.userone.name +
                                ' ' +
                                orderDetail?.conversation?.userone.last_name
                            : 'Unknown'}
                        </td>
                        <td style={{ flex: 0.3 }}>
                          {orderDetail?.headerData.cabin.specifications.size +
                            '-' +
                            orderDetail?.headerData.cabin.specifications.name +
                            '-' +
                            orderDetail?.headerData.cabin.specifications.model}
                        </td>
                        <td style={{ flex: 0.4 }}>
                          {orderDetail?.headerData.cabin.company.name}
                        </td>
                        <td style={{ flex: 0.3 }}>
                          {orderDetail?.headerData.requested_count}
                        </td>
                        <td style={{ flex: 0.5 }}>
                          {orderDetail?.headerData.address.city}
                        </td>
                        <td style={{ flex: 0.3 }}>
                          {orderDetail?.headerData.chase_type}
                        </td>
                        <td style={{ flex: 0.3 }}>
                          {orderDetail?.headerData.inventory.flip_available ===
                          '1'
                            ? 'Yes'
                            : 'No'}
                        </td>
                        <td style={{ flex: 0.3 }}>-</td>
                        <td style={{ flex: 0.3 }}>-</td>
                        <td style={{ flex: 0.6 }}>
                          {orderDetail?.headerData?.request_data_raw?.location
                            ?.value
                            ? orderDetail?.headerData?.request_data_raw
                                ?.location.value.city
                            : orderDetail?.headerData?.request_data_raw
                                ?.location
                            ? orderDetail?.headerData?.request_data_raw
                                ?.location
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
              </div>

              <div className={cls.rowSection}>
                <span className={cls.sectionSpacer}>
                  {chatType === 'offer' && orderStatus?.value === 1 ? (
                    <>
                      <span className={cls.pendingProgress}>2</span>
                      <span className={cls.pendingSectionSpacerTitle}>
                        Waiting for Pre-Approval & Info Shared
                      </span>
                      {/*  <ReactSVG
                        src={Icons.newTick}
                        className={cls.tickIconNew}
                      />
                      <span className={cls.sectionSpacerTitle}>
                        Pre Approved at{' '}
                        {formatDateTimeToUTC(
                          orderDetail?.headerData?.created_at
                        )}
                      </span> */}
                    </>
                  ) : (
                    <>
                      <span className={cls.sectionSpacer}>
                        <ReactSVG
                          src={Icons.newTick}
                          className={cls.tickIconNew}
                        />
                        <span className={cls.sectionSpacerTitle}>
                          You have Pre-approved the request |{' '}
                          {formatDateTimeToUTC(
                            orderDetail?.headerData?.created_at
                          )}
                        </span>
                        {/* <span className={cls.sectionSpacerTitle}>
                          Reservation
                          {orderDetail?.conversation?.user_one ===
                          Number(user_id)
                            ? ' Recived '
                            : ' Sent '}
                          at{' '}
                          {formatDateTimeToUTC(
                            orderDetail?.headerData?.created_at
                          )}
                        </span> */}
                      </span>

                      {/* <span className={cls.pendingProgress}>2</span>
                      <span className={cls.pendingSectionSpacerTitle}>
                        Reservation Pre-approval & Info Shared
                      </span> */}
                    </>
                  )}
                </span>
                {chatType === 'offer' ? (
                  containersItemsState[0]?.orderStatus === 1 ? (
                    <div className={cls.sectionDetailInprogress}>
                      <div className={cls.numberSelectDiv}>
                        <span className={cls.numberCounterTitle}>
                          Lorem Ipsum is simply dummy text of the printing
                          industry.
                        </span>
                        <span className={cls.numberSelectSpan}>
                          <div
                            className={`${cls.input} ${cls.containerNumberInput}`}>
                            <span
                              className={
                                cls.numberCounterTitle
                              }>{`Manage Number to Pre-Approve `}</span>
                            <div className={cls.numbercounter}>
                              <Button
                                className={
                                  value <= 1
                                    ? cls.numberbuttonDisable
                                    : cls.numberbutton
                                }
                                onClick={(e) => {
                                  decrement();
                                }}>
                                -
                              </Button>
                              <span className={cls.numbervalue}>{value}</span>
                              <Button
                                className={
                                  value >= requestedCount
                                    ? cls.numberbuttonDisable
                                    : cls.numberbutton
                                }
                                onClick={(e) => {
                                  increment();
                                }}>
                                +
                              </Button>
                            </div>
                          </div>
                        </span>
                      </div>
                      <table id="table" className={cls.listPreApprove}>
                        <thead id="amirbios1">
                          <tr id="amirbios2" className={cls.theadPreApprove}>
                            <th style={{ flex: 0.2 }}>#</th>
                            <th style={{ flex: 0.5 }}>Size</th>
                            <th style={{ flex: 0.5 }}>SSL</th>
                            <th style={{ flex: 0.6 }}>Container #</th>
                            <th style={{ flex: 0.5 }}>Chassis #</th>
                            <th style={{ flex: 0.5 }}>Chassis Type</th>
                            <th style={{ flex: 0.5 }}>Pickup/Dropoff</th>
                            <th style={{ flex: 0.8 }}>Pickup Location</th>
                            <th style={{ flex: 0.6 }}>Dropoff Date</th>
                            <th style={{ flex: 0.5 }}>Container Condition</th>
                            <th style={{ flex: 0.5 }}>Container Picture</th>
                          </tr>
                        </thead>
                        <tbody className={cls.tableBody}>
                          {containersItemsState?.map((item, index) => (
                            <tr className={cls.tableRowPreApprove}>
                              <td style={{ flex: 0.2 }}>{index + 1}</td>
                              <td style={{ flex: 0.5 }}>{item.size}</td>
                              <td style={{ flex: 0.5 }}>{item.ssl}</td>
                              <td
                                style={{ flex: 0.6 }}
                                onClick={() => {
                                  handleContainersSelect(item);
                                }}>
                                {item.selectedContainer ? (
                                  <span className={cls.containerSelectBtn}>
                                    {item.selectedContainer?.name}
                                    {/* <ReactSVG
                                    onClick={() => {
                                      addItemToOldList(
                                        [],
                                        item.id,
                                        containersItemsState
                                      );
                                    }}
                                    src={Icons.xCircle}
                                    className={cls.emptyTableIcon}
                                  /> */}
                                  </span>
                                ) : (
                                  <span className={cls.containerSelectBtn}>
                                    Select
                                    <ReactSVG
                                      src={Icons.arrowDownNew}
                                      className={cls.emptyTableIcon}
                                    />
                                  </span>
                                )}
                                111
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.selectedContainer ? (
                                  item.selectedContainer?.chases
                                ) : (
                                  <span className={cls.containerPending}>
                                    <ReactSVG
                                      src={Icons.infoCirclePending}
                                      className={cls.emptyTableIcon}
                                    />
                                    Pending
                                  </span>
                                )}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.selectedContainer ? (
                                  item.selectedContainer?.own_chases === 1 ? (
                                    'Own'
                                  ) : (
                                    'Pool'
                                  )
                                ) : (
                                  <span className={cls.containerPending}>
                                    <ReactSVG
                                      src={Icons.infoCirclePending}
                                      className={cls.emptyTableIcon}
                                    />
                                    Pending
                                  </span>
                                )}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                <Select
                                  isDisabled={
                                    item.selectedContainer ? false : true
                                  }
                                  placeholder="Select"
                                  className={clsx(cls.select)}
                                  style={{
                                    background: 'red !important',
                                  }}
                                  value={form?.pickup_way}
                                  onChange={(pickupWay) => {
                                    updateFormData(
                                      index,
                                      'pickup_way',
                                      pickupWay.value
                                    );
                                    // updateFormData(
                                    //   index,
                                    //   'container_id',
                                    //   item.selectedContainer
                                    //     ? item.selectedContainer?.id
                                    //     : ''
                                    // );
                                    // updateFormApproveDates(index);
                                  }}
                                  options={pickupDropoffOpt}
                                />
                              </td>
                              <td style={{ flex: 0.8 }}>
                                <TextField
                                  disabled={
                                    item.selectedContainer ? false : true
                                  }
                                  type="text"
                                  placeholder="Enter location"
                                  className={cls.input}
                                  onChange={(e) =>
                                    updateFormData(
                                      index,
                                      'pickup_location',
                                      e.target.value
                                    )
                                  }
                                  // value={form?.pickup_location}
                                  InputProps={{ disableUnderline: true }}
                                />
                                {/* <AsyncSelect
                                isDisabled={
                                  item.selectedContainer ? false : true
                                }
                                placeholder="Location"
                                className={cls.loc}
                                value={form?.pickup_location}
                                loadOptions={locationOpt}
                                onChange={(item) =>
                                  updateFormData(
                                    index,
                                    'pickup_location',
                                    item.value.city
                                  )
                                }
                                cacheOptions
                                defaultOptions
                              /> */}
                              </td>
                              <td style={{ flex: 0.6 }}>
                                <TimePickerFromTo
                                  disabled={
                                    item.selectedContainer ? false : true
                                  }
                                  rowId={item.id}
                                  isOpen={openRowId === item.id}
                                  setOpenRowId={setOpenRowId}
                                  toTime={toTime}
                                  setToTime={setToTime}
                                  fromTime={fromTime}
                                  setFromTime={setFromTime}
                                  times={times}
                                  setTimes={setTimes}
                                  index={index}
                                  updateFormData={updateFormData}
                                  // onChange={(e) =>
                                  //   updateFormData(index, 'fromTime', times)
                                  // }
                                />
                              </td>
                              <td style={{ flex: 0.5 }}>
                                <Select
                                  isDisabled={
                                    item.selectedContainer ? false : true
                                  }
                                  placeholder="Select"
                                  className={clsx(cls.select)}
                                  value={form.container_conditions}
                                  onChange={(item) =>
                                    updateFormData(
                                      index,
                                      'container_conditions',
                                      item.value
                                    )
                                  }
                                  options={conditionOpts}
                                />
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.selectedContainer ? (
                                  item.selectedContainer.images.length > 0 ? (
                                    <IconButton
                                      onClick={() =>
                                        handleImageUpload(item, 'hasImage')
                                      }
                                      className={cls.tickIcon}>
                                      <ReactSVG
                                        // onClick={() => {
                                        //   handleImageUpload(item);
                                        // }}
                                        src={Icons.tickSquare}
                                        className={cls.galleryAddedIcon}
                                      />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={() =>
                                        handleImageUpload(item, 'hasImage')
                                      }
                                      className={cls.tickIcon}>
                                      <ReactSVG
                                        // onClick={() => {
                                        //   handleImageUpload(item);
                                        // }}
                                        src={Icons.galleryAddBlue}
                                        className={cls.galleryAddBlueIcon}
                                      />
                                    </IconButton>
                                  )
                                ) : (
                                  <ReactSVG
                                    src={Icons.galleryAdd}
                                    className={cls.galleryAddIcon}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <span className={cls.msgPopupBtns}>
                          <Button
                            disabled={
                              preApproverowIDs.length !== 0 ? false : true
                            }
                            className={clsx(cls.preApproveBtn)}
                            onClick={(e) => {
                              handlePreApproveData(form);
                            }}>
                            <ReactSVG
                              src={Icons.tickCircle}
                              className={cls.emptyTableIcon}
                            />
                            Pre-Approve
                          </Button>
                        </span>
                      </table>
                    </div>
                  ) : (
                    <div className={cls.sectionDetailPassed}>
                      <div className={cls.numberSelectDiv}>
                        <span className={cls.numberCounterTitle}>
                          Lorem Ipsum is simply dummy text of the printing
                          industry.
                        </span>
                      </div>

                      <table id="table" className={cls.listPreApprove}>
                        <thead id="amirbios1">
                          <tr id="amirbios2" className={cls.theadPreApprove}>
                            <th style={{ flex: 0.2 }}>#</th>
                            <th style={{ flex: 0.5 }}>Size</th>
                            <th style={{ flex: 0.5 }}>SSL</th>
                            <th style={{ flex: 0.6 }}>Container #</th>
                            <th style={{ flex: 0.5 }}>Chassis #</th>
                            <th style={{ flex: 0.5 }}>Chassis Type</th>
                            <th style={{ flex: 0.5 }}>Pickup/Dropoff</th>
                            <th style={{ flex: 0.8 }}>Pickup Location</th>
                            <th style={{ flex: 0.6 }}>Dropoff Date</th>
                            <th style={{ flex: 0.5 }}>Container Condition</th>
                            <th style={{ flex: 0.5 }}>Container Picture</th>
                          </tr>
                        </thead>
                        <tbody className={cls.tableBody}>
                          {containersItemsState?.map((item, index) => (
                            <tr className={cls.tableRowPreApprove}>
                              <td style={{ flex: 0.2 }}>{index + 1}</td>
                              <td style={{ flex: 0.5 }}>{item.size}</td>
                              <td style={{ flex: 0.5 }}>{item.ssl}</td>
                              <td
                                style={{ flex: 0.6 }}
                                onClick={() => {
                                  handleContainersSelect(
                                    item,
                                    'preApprovedContainers',
                                    item.preapprovedcontainer?.container.id
                                  );
                                }}>
                                222
                                <span className={cls.containerSelectBtn}>
                                  {item.preapprovedcontainer?.container.name}
                                </span>
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.preapprovedcontainer?.container.chases}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.preapprovedcontainer?.container
                                  .own_chases === 1
                                  ? 'Own'
                                  : 'Pool'}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.preapprovedcontainer?.pickup_way}
                              </td>
                              <td style={{ flex: 0.8 }}>
                                {item.preapprovedcontainer?.pickup_location}
                              </td>
                              <td style={{ flex: 0.6 }}>
                                {extractTime(
                                  item.preapprovedcontainer?.pickup_date_start
                                )}{' '}
                                -{' '}
                                {extractTime(
                                  item.preapprovedcontainer?.pickup_date_end
                                )}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {
                                  item.preapprovedcontainer
                                    ?.container_conditions
                                }
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.preapprovedcontainer?.container.images
                                  .length > 0 ? (
                                  <IconButton
                                    onClick={() =>
                                      handleImageUpload(
                                        item,
                                        'preApprovedImage'
                                      )
                                    }
                                    className={cls.tickIcon}>
                                    <ReactSVG
                                      // onClick={() => {
                                      //   handleImageUpload(item);
                                      // }}
                                      src={Icons.imageView}
                                      className={cls.galleryViewIcon}
                                    />
                                  </IconButton>
                                ) : (
                                  <ReactSVG
                                    src={Icons.galleryAdd}
                                    className={cls.galleryAddIcon}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <span className={cls.usersInfoSecton}>
                        <span className={cls.usersInfoSectonTitle}>
                          Well Done! Thank you for using Smart-Turn. Here is the
                          contact information of the other party.
                        </span>
                        <span className={cls.usersInfoSectonSubTitle}>
                          If you are planning to street turn the empty
                          container, you may use the below link. Once confirmed,
                          please upload the street turn approval receipt in
                          the “completed” section. 
                        </span>
                        <Button
                          className={clsx(cls.usersInfoSectonSSlBtn)}
                          // onClick={(e) => {
                          //   handlePreApproveData(form);
                          // }}
                        >
                          SIA
                        </Button>
                        <Grid
                          className={cls.usersInfoSectonBody}
                          container
                          spacing={2}>
                          <Grid item xs={12} sm={6} lg={6}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.frame}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Name:
                              </span>{' '}
                              Jack Mount
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.locationNew}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Office Address:
                              </span>{' '}
                              4521 campus Drive, Irvine, CA
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.buildings}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Company:
                              </span>{' '}
                              Mount Trucking
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.locationNew}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Yard Address:
                              </span>{' '}
                              SCAC1242
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.callCalling}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Phone:
                              </span>{' '}
                              626866474
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.buildings}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                SCAC:
                              </span>{' '}
                              DOT1423
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.sms}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Email:
                              </span>{' '}
                              Jack.Mount.trucking@gmail.com
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.buildings}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                DOT:
                              </span>
                              DOT1423
                            </span>
                          </Grid>
                        </Grid>
                      </span>
                    </div>
                  )
                ) : containersItemsState[0]?.orderStatus === 1 ? (
                  <span className={cls.sectionDetailEmpty}>
                    <span className={cls.emptyTableHandler}>
                      <ReactSVG
                        src={Icons.emptyHandle}
                        className={cls.emptyTableIcon}
                      />
                      This step is in progress Once this step is approved, you
                      will see the information
                    </span>
                  </span>
                ) : (
                  <div className={cls.sectionDetailPassed}>
                    <div className={cls.numberSelectDiv}>
                      <span className={cls.numberCounterTitle}>
                        Lorem Ipsum is simply dummy text of the printing
                        industry.
                      </span>
                    </div>

                    <table id="table" className={cls.listPreApprove}>
                      <thead id="amirbios1">
                        <tr id="amirbios2" className={cls.theadPreApprove}>
                          <th style={{ flex: 0.2 }}>#</th>
                          <th style={{ flex: 0.5 }}>Size</th>
                          <th style={{ flex: 0.5 }}>SSL</th>
                          <th style={{ flex: 0.6 }}>Container #</th>
                          <th style={{ flex: 0.5 }}>Chassis #</th>
                          <th style={{ flex: 0.5 }}>Chassis Type</th>
                          <th style={{ flex: 0.5 }}>Pickup/Dropoff</th>
                          <th style={{ flex: 0.8 }}>Pickup Location</th>
                          <th style={{ flex: 0.6 }}>Dropoff Date</th>
                          <th style={{ flex: 0.5 }}>Container Condition</th>
                          <th style={{ flex: 0.5 }}>Container Picture</th>
                        </tr>
                      </thead>
                      <tbody className={cls.tableBody}>
                        {containersItemsState?.map((item, index) => (
                          <tr className={cls.tableRowPreApprove}>
                            <td style={{ flex: 0.2 }}>{index + 1}</td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer.sizType}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer.ssl}
                            </td>
                            <td
                              style={{ flex: 0.6 }}
                              onClick={() => {
                                handleContainersSelect(
                                  item,
                                  'preApprovedContainers',
                                  item?.preapprovedcontainer.container_id
                                );
                              }}>
                              444
                              <span className={cls.containerSelectBtn}>
                                {item?.preapprovedcontainer.container.name}
                              </span>
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item?.preapprovedcontainer.container.chases}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item?.preapprovedcontainer.container
                                .own_chases === 1
                                ? 'Own'
                                : 'Pool'}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer.pickup_way}
                            </td>
                            <Tooltip
                              arrow
                              title={item.preapprovedcontainer.pickup_location}
                              placement="top">
                              <td
                                className={cls.preApprovePickupLocation}
                                style={{ flex: 0.8 }}>
                                {item.preapprovedcontainer.pickup_location}
                              </td>
                            </Tooltip>
                            <td style={{ flex: 0.6 }}>
                              {extractTime(
                                item.preapprovedcontainer.pickup_date_start
                              )}{' '}
                              -{' '}
                              {extractTime(
                                item.preapprovedcontainer.pickup_date_end
                              )}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer.container_conditions}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              <Tooltip
                                arrow
                                title="View pictures"
                                placement="top">
                                {item?.preapprovedcontainer?.container?.images
                                  ?.length > 0 ? (
                                  <IconButton
                                    onClick={() =>
                                      handleImageUpload(
                                        item,
                                        'preApprovedImage'
                                      )
                                    }
                                    className={cls.tickIcon}>
                                    <ReactSVG
                                      // onClick={() => {
                                      //   handleImageUpload(item);
                                      // }}
                                      src={Icons.imageView}
                                      className={cls.galleryViewIcon}
                                    />
                                  </IconButton>
                                ) : (
                                  <ReactSVG
                                    src={Icons.galleryAdd}
                                    className={cls.galleryAddIcon}
                                  />
                                )}
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <span className={cls.usersInfoSecton}>
                      <span className={cls.usersInfoSectonTitle}>
                        Well Done! Thank you for using Smart-Turn. Here is the
                        contact information of the other party.
                      </span>
                      <span className={cls.usersInfoSectonSubTitle}>
                        If you are planning to street turn the empty container,
                        you may use the below link. Once confirmed, please
                        upload the street turn approval receipt in
                        the “completed” section. 
                      </span>
                      <Button
                        className={clsx(cls.usersInfoSectonSSlBtn)}
                        // onClick={(e) => {
                        //   handlePreApproveData(form);
                        // }}
                      >
                        SIA
                      </Button>
                      <Grid
                        className={cls.usersInfoSectonBody}
                        container
                        spacing={2}>
                        <Grid item xs={12} sm={6} lg={6}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.frame}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Name:
                            </span>{' '}
                            Jack Mount
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.locationNew}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Office Address:
                            </span>{' '}
                            4521 campus Drive, Irvine, CA
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.buildings}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Company:
                            </span>{' '}
                            Mount Trucking
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.locationNew}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Yard Address:
                            </span>{' '}
                            SCAC1242
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.callCalling}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Phone:
                            </span>{' '}
                            626866474
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.buildings}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              SCAC:
                            </span>{' '}
                            DOT1423
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.sms}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Email:
                            </span>{' '}
                            Jack.Mount.trucking@gmail.com
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.buildings}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              DOT:
                            </span>
                            DOT1423
                          </span>
                        </Grid>
                      </Grid>
                    </span>
                  </div>
                )}
              </div>

              <div className={cls.rowSection}>
                <span className={cls.sectionSpacer}>
                  {chatType === 'offer' && orderStatus?.value > 4 ? (
                    <>
                      <ReactSVG
                        src={Icons.newTick}
                        className={cls.tickIconNew}
                      />
                      <span className={cls.sectionSpacerTitle}>
                        Pre Approved at{' '}
                        {formatDateTimeToUTC(
                          orderDetail?.headerData?.created_at
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={cls.pendingProgress}>3</span>
                      <span className={cls.pendingSectionSpacerTitle}>
                        Waiting for Approval & Street Turn Completed
                      </span>
                    </>
                  )}
                </span>
                {chatType === 'offer' ? (
                  containersItemsState[0]?.orderStatus === 3 ? (
                    <span className={cls.sectionDetail}>
                      <table id="table" className={cls.list}>
                        <thead id="amirbios1">
                          <tr id="amirbios2" className={cls.thead}>
                            <th style={{ flex: 0.2 }}>#</th>
                            <th style={{ flex: 0.5 }}>Size</th>
                            <th style={{ flex: 0.5 }}>SSL</th>
                            <th style={{ flex: 0.5 }}>Container #</th>
                            <th style={{ flex: 0.5 }}>Chassis #</th>
                            <th style={{ flex: 0.5 }}>Chassis Type</th>
                            <th style={{ flex: 0.7 }}>Pickup Location</th>
                            <th style={{ flex: 0.7 }}>Dropoff Date</th>
                            <th style={{ flex: 0.7 }}>Container Condition</th>
                            <th style={{ flex: 0.5 }}>Approval</th>
                            <th style={{ flex: 0.7 }}>Status</th>
                          </tr>
                        </thead>
                        <tbody className={cls.tableBody}>
                          {containersItemsState?.map((item, index) => (
                            <tr className={cls.tableRowPreApprove}>
                              <td style={{ flex: 0.2 }}>{index + 1}</td>
                              <td style={{ flex: 0.5 }}>{item.size}</td>
                              <td style={{ flex: 0.5 }}>{item.ssl}</td>
                              <td
                                style={{ flex: 0.6 }}
                                onClick={() => {
                                  handleContainersSelect(
                                    item,
                                    'preApprovedContainers',
                                    item.approvedcontainer?.container?.id
                                  );
                                }}>
                                777
                                <span className={cls.containerSelectBtn}>
                                  {item.approvedcontainer?.container?.name}
                                </span>
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.approvedcontainer?.container?.chases}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.approvedcontainer?.container
                                  ?.own_chases === 1
                                  ? 'Own'
                                  : 'Pool'}
                              </td>
                              <td style={{ flex: 0.8 }}>
                                {item.approvedcontainer?.pickup_location}
                              </td>
                              <td style={{ flex: 0.6 }}>
                                {extractTime(
                                  item.approvedcontainer?.pickup_date_start
                                )}{' '}
                                -{' '}
                                {extractTime(
                                  item.approvedcontainer?.pickup_date_end
                                )}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.approvedcontainer?.container_conditions}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                <Tooltip
                                  arrow
                                  title="View Approval"
                                  placement="top">
                                  {item.approvedcontainer.container?.images
                                    ?.length > 0 ? (
                                    <IconButton
                                      onClick={() =>
                                        handleImageUpload(
                                          item,
                                          'preApprovedImage'
                                        )
                                      }
                                      className={cls.tickIcon}>
                                      <ReactSVG
                                        // onClick={() => {
                                        //   handleImageUpload(item);
                                        // }}
                                        src={Icons.imageView}
                                        className={cls.galleryViewIcon}
                                      />
                                    </IconButton>
                                  ) : (
                                    <ReactSVG
                                      src={Icons.galleryAdd}
                                      className={cls.galleryAddIcon}
                                    />
                                  )}
                                </Tooltip>
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.approvedcontainer.approve_status ===
                                '0' ? (
                                  <span className={cls.declinedText}>
                                    Declined
                                  </span>
                                ) : (
                                  <span className={cls.confirmedText}>
                                    Confirmed
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </span>
                  ) : (
                    <span className={cls.sectionDetailEmpty}>
                      <span className={cls.emptyTableHandler}>
                        <ReactSVG
                          src={Icons.emptyHandle}
                          className={cls.emptyTableIcon}
                        />
                        This step is in progress Once this step is approved, you
                        will see the information
                      </span>
                    </span>
                  )
                ) : containersItemsState[0]?.orderStatus === 4 ? (
                  <span className={cls.sectionDetail}>
                    <table id="table" className={cls.listPreApprove}>
                      <thead id="amirbios1">
                        <tr id="amirbios2" className={cls.theadPreApprove}>
                          <th style={{ flex: 0.2 }}>#</th>
                          <th style={{ flex: 0.5 }}>Size</th>
                          <th style={{ flex: 0.5 }}>SSL</th>
                          <th style={{ flex: 0.6 }}>Container #</th>
                          <th style={{ flex: 0.5 }}>Chassis #</th>
                          <th style={{ flex: 0.5 }}>Chassis Type</th>
                          <th style={{ flex: 0.8 }}>Pickup Location</th>
                          <th style={{ flex: 0.6 }}>Dropoff Date</th>
                          <th style={{ flex: 0.7 }}>Container Condition</th>
                          <th style={{ flex: 1.2 }}>Approval</th>
                        </tr>
                      </thead>
                      <tbody className={cls.tableBody}>
                        {containersItemsState?.map((item, index) => (
                          <tr className={cls.tableRowPreApprove}>
                            <td style={{ flex: 0.2 }}>{index + 1}</td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.sizType}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.ssl}
                            </td>
                            <td
                              style={{ flex: 0.6 }}
                              onClick={() => {
                                handleContainersSelect(
                                  item,
                                  'preApprovedContainers',
                                  item.preapprovedcontainer?.container.id
                                );
                              }}>
                              555
                              <span className={cls.containerSelectBtn}>
                                {item.preapprovedcontainer?.container.name}
                              </span>
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.container.chases}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.container
                                .own_chases === 1
                                ? 'Own'
                                : 'Pool'}
                            </td>
                            <Tooltip
                              arrow
                              title={item.preapprovedcontainer?.pickup_location}
                              placement="top">
                              <td
                                className={cls.preApprovePickupLocation}
                                style={{ flex: 0.8 }}>
                                {item.preapprovedcontainer?.pickup_location}
                              </td>
                            </Tooltip>
                            <td style={{ flex: 0.6 }}>
                              {extractTime(
                                item.preapprovedcontainer?.pickup_date_start
                              )}{' '}
                              -{' '}
                              {extractTime(
                                item.preapprovedcontainer?.pickup_date_end
                              )}
                            </td>
                            <td style={{ flex: 0.7 }}>
                              <span className={cls.containerConditions}>
                                {
                                  item.preapprovedcontainer
                                    ?.container_conditions
                                }
                              </span>
                            </td>
                            <td style={{ flex: 1.2 }}>
                              <Tooltip
                                arrow
                                title="Confirm & Upload Approval"
                                placement="top">
                                <Button
                                  style={{
                                    background:
                                      formApprove[index]?.approve_status === 1
                                        ? '#1fc16b47'
                                        : 'transparent',
                                  }}
                                  className={clsx(cls.cunfirmBtn)}
                                  onClick={(e) => {
                                    handleImageUpload(
                                      item,
                                      'approvalFile',
                                      item.preapprovedcontainer?.container.id
                                    );
                                    // updateFormApproveData(
                                    //   index,
                                    //   'approve_status',
                                    //   1
                                    // );
                                    // updateFormApproveData(
                                    //   index,
                                    //   'container_id',
                                    //   item.preApprovedContainersList
                                    //     ? item.preApprovedContainersList
                                    //         ?.container_id
                                    //     : ''
                                    // );
                                  }}>
                                  Confirm
                                </Button>
                              </Tooltip>
                              <Button
                                style={{
                                  background:
                                    formApprove[index]?.approve_status === 0
                                      ? '#ff000033'
                                      : 'transparent',
                                }}
                                className={clsx(cls.declineBtn)}
                                onClick={(e) => {
                                  updateFormApproveData(
                                    index,
                                    'approve_status',
                                    0
                                  );
                                  updateFormApproveData(
                                    index,
                                    'container_id',
                                    item.preApprovedContainersList
                                      ? item.preapprovedcontainer?.container.id
                                      : ''
                                  );
                                }}>
                                Decline
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <span className={cls.msgPopupBtns}>
                        <Button
                          disabled={formApprove[0].container_id ? false : true}
                          className={clsx(cls.preApproveBtn)}
                          onClick={(e) => {
                            handleApproveData(formApprove);
                          }}>
                          <ReactSVG
                            src={Icons.tickCircle}
                            className={cls.emptyTableIcon}
                          />
                          Approve
                        </Button>
                      </span>
                    </table>
                  </span>
                ) : containersItemsState[0]?.orderStatus === 3 ? (
                  <span className={cls.sectionDetail}>
                    <table id="table" className={cls.list}>
                      <thead id="amirbios1">
                        <tr id="amirbios2" className={cls.thead}>
                          <th style={{ flex: 0.2 }}>#</th>
                          <th style={{ flex: 0.5 }}>Size</th>
                          <th style={{ flex: 0.5 }}>SSL</th>
                          <th style={{ flex: 0.5 }}>Container #</th>
                          <th style={{ flex: 0.5 }}>Chassis #</th>
                          <th style={{ flex: 0.5 }}>Chassis Type</th>
                          <th style={{ flex: 0.7 }}>Pickup/Dropoff</th>
                          <th style={{ flex: 0.7 }}>Pickup Location</th>
                          <th style={{ flex: 0.7 }}>Dropoff Date</th>
                          <th style={{ flex: 0.7 }}>Status</th>
                          <th style={{ flex: 0.5 }}>Approval</th>
                        </tr>
                      </thead>
                      <tbody className={cls.tableBody}>
                        {containersItemsState?.map((item, index) => (
                          <tr className={cls.tableRowPreApprove}>
                            <td style={{ flex: 0.2 }}>{index + 1}</td>
                            <td style={{ flex: 0.5 }}>{item.size}</td>
                            <td style={{ flex: 0.5 }}>{item.ssl}</td>
                            <td
                              style={{ flex: 0.6 }}
                              onClick={() => {
                                handleContainersSelect(
                                  item,
                                  'preApprovedContainers',
                                  item.approvedcontainer.container.id
                                );
                              }}>
                              666
                              <span className={cls.containerSelectBtn}>
                                {item.approvedcontainer.container.name}
                              </span>
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.approvedcontainer.container.chases}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.approvedcontainer.container.own_chases === 1
                                ? 'Own'
                                : 'Pool'}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.approvedcontainer.pickup_way}
                            </td>
                            <td style={{ flex: 0.8 }}>
                              {item.approvedcontainer.pickup_location}
                            </td>
                            <td style={{ flex: 0.6 }}>
                              {extractTime(
                                item.approvedcontainer.pickup_date_start
                              )}{' '}
                              -{' '}
                              {extractTime(
                                item.approvedcontainer.pickup_date_end
                              )}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.approvedcontainer.container_conditions}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              <Tooltip
                                arrow
                                title="View pictures"
                                placement="top">
                                {item.approvedcontainer.container?.images
                                  ?.length > 0 ? (
                                  <IconButton
                                    onClick={() =>
                                      handleImageUpload(
                                        item,
                                        'preApprovedImage'
                                      )
                                    }
                                    className={cls.tickIcon}>
                                    <ReactSVG
                                      // onClick={() => {
                                      //   handleImageUpload(item);
                                      // }}
                                      src={Icons.imageView}
                                      className={cls.galleryViewIcon}
                                    />
                                  </IconButton>
                                ) : (
                                  <ReactSVG
                                    src={Icons.galleryAdd}
                                    className={cls.galleryAddIcon}
                                  />
                                )}
                              </Tooltip>
                            </td>{' '}
                            <td style={{ flex: 0.5 }}>
                              {item.approvedcontainer.approve_status === '0' ? (
                                <span className={cls.declinedText}>
                                  Declined
                                </span>
                              ) : (
                                <span className={cls.confirmedText}>
                                  Confirmed
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </span>
                ) : (
                  <span className={cls.sectionDetailEmpty}>
                    <span className={cls.emptyTableHandler}>
                      <ReactSVG
                        src={Icons.emptyHandle}
                        className={cls.emptyTableIcon}
                      />
                      This step is in progress Once this step is approved, you
                      will see the information
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
